import useApi from "./useApi";
import * as Yup from "yup";
import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { community, login } from "../store/authSlice";
import { loadingHandler } from "../store/loadingSlice";
import { toast } from "react-toastify";
import useLoader from "./useLoader";
import { amenityNameFormatter } from "../helpers";

const useBooking = () => {
  const api = useApi();
  const { setLoading } = useLoader();
  const navigate = useNavigate();
  const { communityId } = useSelector((state) => state?.authReducer);
  const phoneRegExp = /^(\(\+\d{1,3}\))?\s?\d{10}$/;
  const cnicRegExp = /^\d{5}-\d{7}-\d{1}$/;

  const [bookingList, setBookingList] = useState([]);
  const [seriesDetailId, setSeriesDetailId] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openAddNew, setOpenAddNew] = useState(false);

  const [bookingData, setBookingData] = useState({
    ammenityBookingID: 0,
    userDetailId: null,
    fromDate: "",
    toDate: "",
    bookingAmount: 0,
    advanceAmount: 0,
    eventTitle: "",
    eventDescription: "",
    cellNo: "",
    name: "",
  });

  const form = useFormik({
    initialValues: bookingData,
    enableReinitialize: true,
    isInitialValid: false,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Required"),
      cellNo: Yup.string()
        .required("Required")
        .matches(phoneRegExp, "Phone number is not valid"),
    }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const data = {
        ...form?.values,
        cellNo: `+92${form?.values?.cellNo}`,
        communityId,
        communitySeriesDetailId: seriesDetailId,
      };
      api
        .addBooking({
          data,
        })
        .then((response) => {
          if (response?.data?.headers?.code === 200) {
            toast.success(response?.data?.headers?.msg);
            setOpenAddNew(false);
          } else {
            toast.error(response?.data?.headers?.msg);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  // function getAmmenityDetailBySeriesDetailId(id) {
  //   setLoading(true);
  //   api
  //     ?.GetAmmenityDetailBySeriesDetailId({ id })
  //     .then((response) => {
  //       if (response?.data?.headers?.code === 200) {
  //         console.log(response?.data?.body);
  //       } else {
  //         toast.error(response?.data?.headers?.message);
  //         return;
  //       }
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }

  const handleGetAllBookingListing = async ({
    pageNo = 1,
    search = "",
    sortField = "",
    sortDirection = "desc",
    page = 10,
    communitySeriesDetailId,
  }) => {
    setSeriesDetailId(communitySeriesDetailId);
    const data = {
      pageNumber: 1,
      pageSize: page,
      totalRowsCount: 0,
      sortField,
      sortDirection,
      search,
      filter: {
        communityId,
        communitySeriesDetailId,
      },
    };
    setLoading(true);
    const result = await api?.GetAllBookingListing(data);
    if (result?.data?.headers?.code === 200) {
      setBookingList(result?.data?.body?.data);
    } else {
      toast.error(result?.data?.headers?.message);
    }
    setLoading(false);
  };

  function getUserByCellNumber(cellNo) {
    setLoading(true);
    const number = `+92${cellNo}`;
    api
      ?.GetUserDetailsByCellno(cellNo)
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          if (response?.data?.body !== null) {
            const details = response?.data?.body;
            setBookingData((prev) => ({
              ...prev,
              userDetailId: details?.userDetailId,
              cellNo: form?.values?.cellNo,
              name: details?.userFullName,
            }));
          } else {
            setBookingData((prev) => ({
              ...prev,
              userDetailId: null,
              name: "",
            }));
          }
        } else {
          toast.error(response?.data?.headers?.message);
          return;
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (form.values.cellNo?.toString().length === 10 && selectedItem === null) {
      setBookingData((prev) => ({
        ...form?.values,
        cellNo: form.values.cellNo,
      }));
      getUserByCellNumber(form.values?.cellNo);
    }
  }, [form.values.cellNo]);

  return {
    form,
    bookingList,
    handleGetAllBookingListing,
    setSelectedItem,
    selectedItem,
    openAddNew,
    setOpenAddNew,
    // getAmmenityDetailBySeriesDetailId,
  };
};

export default useBooking;
