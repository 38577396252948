import React from "react";
import classes from "./HeaderAction.module.css";
import { FaArrowLeft } from "react-icons/fa6";
const HeaderAction = ({
  onClick,
  title,
  icon = <FaArrowLeft color="#fff" />,
}) => {
  return (
    <div className={classes.wrapper}>
      <span className={classes.bacKBtn} onClick={onClick}>
        {icon}
      </span>
      <h2 className={classes.title}>{title}</h2>
    </div>
  );
};

export default HeaderAction;
