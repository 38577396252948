import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadingHandler } from "../store/loadingSlice";
const useLoader = () => {
  const loading = useSelector((state) => state?.loadingReducer?.loading);
  const dispatch = useDispatch();
  const setLoading = (state) => {
    dispatch(loadingHandler(state));
  };
  return { loading, setLoading };
};

export default useLoader;
