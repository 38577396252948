import { LuInfo } from "react-icons/lu";
import MyButton from "../../components/MyButton";

import Classes from "./AreYouSureModal.module.css";

import Modal from "@mui/material/Modal";
import React from "react";

const AreYouSureModal = ({
  show,
  setShow,
  handler,
  title = "Are You Sure Want to Delete",
}) => {
  return (
    <>
      <style>{`
      .modal-sm {
        --bs-modal-width: 350px;
      }
      .modal-content{
        border-radius: 16px;
      }
    `}</style>
      <Modal
        open={show}
        onClose={setShow}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <div className={Classes.container}>
          <div className={Classes.description}>
            <div className={Classes.iconWrapper}>
              <LuInfo size={40} color="var(--main-color)" />
            </div>
            <p>{title}</p>
          </div>

          <div className={Classes.actionsWrapper}>
            <MyButton
              btnText={"Cancel"}
              handler={() => {
                setShow(false);
              }}
              className={Classes["cancel-button"]}
            />
            <MyButton
              btnText={"Confirm"}
              handler={() => {
                setShow(false);
                if (typeof handler == "function") {
                  handler();
                }
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AreYouSureModal;
