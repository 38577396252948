import { Box, Grid } from "@mui/material";
import React from "react";
import useBooking from "../../Hooks/useBooking";
import DateField from "../DateField";
import MyInput from "../MyInput";
import classes from "./AddBookingForm.module.css";

const AddBookingForm = ({ form }) => {
  return (
    <div className={classes["formCardMainWrapper"]}>
      <Box
        sx={{
          "& .MuiTextField-root": { my: 1, ml: 0, width: "100%" },
        }}
        className={classes["formWrapper"]}
        noValidate
        autoComplete="off"
      >
        <div className={classes["header"]}>
          <p>Booking Dates and Time</p>
        </div>
        <DateField
          value={form?.values.fromDate}
          setter={form.handleChange("fromDate")}
          error={form.errors.fromDate}
          label={"From Date Time"}
          datetimePicker={true}
        />
        <DateField
          name="toDate"
          label={"To Date Time"}
          value={form?.values.toDate}
          setter={form.handleChange("toDate")}
          error={form.errors.toDate}
          datetimePicker={true}
        />
        {/* <Grid container columnGap={2}>
          <Grid lg={6} sm={12} xs={12}>
            <MyInput
              name="fromDate"
              label="From"
              value={form?.values.fromDate}
              setter={form.handleChange("fromDate")}
              error={form.errors.fromDate}
            />
          </Grid>
          <Grid lg={5.6} sm={12} xs={12} container columnGap={1}>
            <MyInput
              name="toDate"
              label="To"
              value={form?.values.toDate}
              setter={form.handleChange("toDate")}
              error={form.errors.toDate}
            />
          </Grid>
        </Grid> 
        <div className={classes["header"]}>
          <p>Booking Time</p>
        </div>
        <Grid container columnGap={2}>
          <Grid lg={6} sm={12} xs={12}>
            <MyInput
              name="startTime"
              label="Start Time"
              value={form?.values.startTime}
              setter={form.handleChange("startTime")}
              error={form.errors.startTime}
            />
          </Grid>
          <Grid lg={5.6} sm={12} xs={12} container columnGap={1}>
            <MyInput
              name="endTime"
              label="End Time"
              value={form?.values.endTime}
              setter={form.handleChange("endTime")}
              error={form.errors.endTime}
            />
          </Grid>
        </Grid>
        */}
        <div className={classes["header"]}>
          <p>Booking Charges</p>
        </div>
        <Grid container columnGap={2}>
          <Grid lg={6} sm={12} xs={12}>
            <MyInput
              type="number"
              name="bookingAmount"
              label="Charges"
              value={form?.values.bookingAmount}
              setter={form.handleChange("bookingAmount")}
              error={form.errors.bookingAmount}
            />
          </Grid>
          <Grid lg={5.6} sm={12} xs={12} container columnGap={1}>
            <MyInput
              type="number"
              name="advanceAmount"
              label="Advance Collected"
              value={form?.values.advanceAmount}
              setter={form.handleChange("advanceAmount")}
              error={form.errors.advanceAmount}
            />
          </Grid>
        </Grid>
        <div className={classes["header"]}>
          <p>Additional Details</p>
        </div>
        <Grid container columnGap={2}>
          <Grid lg={6} sm={12} xs={12}>
            <MyInput
              name="eventTitle"
              label="Event Title"
              value={form?.values.eventTitle}
              setter={form.handleChange("eventTitle")}
              error={form.errors.eventTitle}
            />
          </Grid>
          <Grid lg={5.6} sm={12} xs={12} container columnGap={1}>
            <MyInput
              name="eventDescription"
              label="Description"
              value={form?.values.eventDescription}
              setter={form.handleChange("eventDescription")}
              error={form.errors.eventDescription}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default AddBookingForm;
