import React from "react";
import { BsChevronDown, BsChevronRight } from "react-icons/bs";
import classes from "./TabComponent.module.css";
const TabComponent = ({ text, isOpen = false, onClick }) => {
  return (
    <div
      className={[classes["mainWrapper"], isOpen && classes["active"]].join(
        " "
      )}
      onClick={onClick}
    >
      <p>{text}</p>
      {isOpen ? <BsChevronDown size={15} /> : <BsChevronRight size={15} />}
    </div>
  );
};

export default TabComponent;
