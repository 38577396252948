import * as React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Box, Grid } from "@mui/material";
import MyInput from "../../../../components/MyInput";
import classes from "./ParkingAllotmentBSheet.module.css";
import { MdCancel } from "react-icons/md";
import MySelect from "../../../../components/MySelect";
import MyButton from "../../../../components/MyButton";
import DrawerWrapper from "../../../../components/DrawerWrapper";
import SearchBox from "../../../../components/SearchBox";

const ParkingAllotmentBSheet = ({
  open,
  setOpen,
  handleChange,
  form,
  handleSubmit,
}) => {
  const options = [
    {
      label: "Member",
      value: "member",
    },
    {
      label: "Staff",
      value: "staff",
    },
  ];
  return (
    <>
      <Box
        sx={{
          "& .MuiTextField-root": { my: 1, ml: 0, width: "100%" },
        }}
        className={classes["formWrapper"]}
        noValidate
        autoComplete="off"
      >
        <MySelect
          name={"propertyType"}
          value={form?.propertyType}
          label="Property Type"
          setter={(val) => handleChange("propertyType", val)}
          options={options}
        />
        {form?.propertyType === "member" ? (
          <SearchBox
            label={"Search"}
            name={"propertyName"}
            value={form?.propertyName}
            setter={(e) => handleChange(e.target.name, e.target.value)}
          />
        ) : (
          <>
            <MySelect
              name={"staffType"}
              value={form?.staffType}
              label="Staff Type"
              setter={(val) => handleChange("staffType", val)}
              options={null}
            />
            <MySelect
              name={"propertyName"}
              value={form?.propertyName}
              label="Name"
              setter={(val) => handleChange("propertyName", val)}
              options={null}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default ParkingAllotmentBSheet;
