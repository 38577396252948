import React, { useState } from "react";
import classes from "./Dashboard.module.css";
import MainDashboard from "./MainDashboard";
import AmenitiesDashboard from "./AmenitiesDashboard";
import { useSelector } from "react-redux";
import TabComponent from "../../../components/TabComponent";
import { GoSearch } from "react-icons/go";
import { FaArrowLeft } from "react-icons/fa6";

const Dashboard = () => {
  const [filter, setFilter] = useState({ value: "month", label: "Month" });

  const complainData = {
    complainNo: "005525",
    date: "12 Jan 2024",
    userAddress: "Flat/301 - Block A - 1st Floor",
    username: "Haris Ali",
    complainTitle: "Water Leakage",
    complainDescription:
      "Dear Unionis simply dummy text of the printing and typesetting industry...",
  };
  const announcementData = {
    status: "Active",
    date: "12 Jan 2024",
    announcementTitle: "Pay Maintenance on time",
    announcementDescription:
      "Dear owners/Tenants,  Please pay maintenance as soon as possible because...",
  };
  const { communityName } = useSelector((state) => state?.authReducer);
  const { amenitiesList } = useSelector((state) => state?.generalApiReducer);
  const [showDetailedAmenity, setShowDetailedAmenity] = useState(false);
  const [selectedAmenityId, setSelectedAmenityId] = useState(0);

  return (
    <>
      <h1 className={classes["username-heading"]}>Hi, Saad Ashraf</h1>
      <div className={[classes["tabsWrapper"]].join(" ")}>
        <div className={[classes.addMenuBtn, "c-p"].join(" ")}>
          {showDetailedAmenity ? (
            <FaArrowLeft
              color={"var(--secondary-color)"}
              size={40}
              onClick={() => {
                setShowDetailedAmenity(false);
                setSelectedAmenityId(0);
              }}
            />
          ) : (
            <GoSearch color="var(--secondary-color)" size={25} />
          )}
        </div>
        <div className="tabs-section">
          {amenitiesList?.map((item, index) => (
            <TabComponent
              key={index}
              text={item?.ammenityName}
              onClick={() => {
                setShowDetailedAmenity(true);
                setSelectedAmenityId(item?.ammenityId);
              }}
              isOpen={item?.ammenityId === selectedAmenityId}
            />
          ))}
        </div>
      </div>
      {!showDetailedAmenity ? (
        <>
          <MainDashboard
            complainData={complainData}
            announcementData={announcementData}
            filter={filter}
          />
        </>
      ) : (
        <AmenitiesDashboard amenityId={selectedAmenityId} />
      )}
    </>
  );
};

export default Dashboard;
