import useApi from "./useApi";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { otpReducer } from "../store/authSlice";
import { toast } from "react-toastify";
import useLoader from "./useLoader";
import { amenityNameFormatter } from "../helpers";

const useAddOns = () => {
  const api = useApi();
  const [open, setOpen] = useState(false);
  const { loading, setLoading } = useLoader();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    parkingSeriesDetailId: null,
    communitySeriesDetailId: null,
    userId: null,
    roleId: null,
    propertyTypeId: null,
  });
  const [userDetails, setUserDetails] = useState(null);
  const [userList, setUserList] = useState(null);

  useEffect(() => {
    if (form?.roleId !== null && form?.roleId?.value !== undefined) {
      userByRoleIdHandler();
    }
  }, [form?.roleId]);

  function getAmmenityDetailBySeriesDetailId(id) {
    setForm((prev) => ({ ...prev, communitySeriesDetailId: id }));
    // setLoading(true);
    api
      ?.GetAmmenityDetailBySeriesDetailId({ id })
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          if (response?.data?.body !== null) {
            const details = response?.data?.body;
            var address = null;
            if (details?.isAddOn) {
              if (details?.ammenityMapping !== null) {
                address = {
                  ammenityName: details?.ammenityMapping?.ammenityName,
                  unitName: details?.ammenityMapping?.unitName,
                  prefix: details?.ammenityMapping?.prefix,
                };
                setUserDetails({
                  amenityddress: amenityNameFormatter(
                    address?.ammenityName,
                    address?.prefix,
                    address?.unitName
                  ),
                });
                setForm((prev) => ({
                  ...prev,
                  communitySeriesDetailId:
                    details?.ammenityMapping?.communitySeriesDetailId,
                  propertyTypeId: {
                    value: 3,
                  },
                }));
              } else if (details?.userDetailList?.length !== 0) {
                setForm((prev) => ({
                  ...prev,
                  communitySeriesDetailId: null,
                  roleId: {
                    value: details?.userDetailList[0]?.roleID,
                  },
                  userId: {
                    value: details?.userDetailList[0]?.userDetailID,
                  },
                  propertyTypeId: {
                    value: details?.userDetailList[0]?.propertyTypeID,
                  },
                }));
              } else {
                setForm((prev) => ({
                  ...prev,
                  communitySeriesDetailId: null,
                  propertyTypeId: {
                    value: 3,
                  },
                }));
              }
              setOpen(true);
            } else {
              address = {
                ammenityName: details.ammenityName,
                unitName: details?.unitName,
                prefix: details?.prefix,
              };
              setUserDetails({
                amenityddress: amenityNameFormatter(
                  address?.ammenityName,
                  address?.prefix,
                  address?.unitName
                ),
              });
              if (details?.userDetailList !== []) {
                details?.userDetailList?.map((item, index) => {
                  if (item.propertyTypeID === 1) {
                    setUserDetails((prev) => ({
                      ...prev,
                      ...item,
                      role: { value: item?.roleID },
                      cellNo: item?.cellNo?.slice(3),
                    }));
                  }
                });
              }
            }
          }
        } else {
          toast.error(response?.data?.headers?.message);
          return;
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const allotParkingHandler = () => {
    setLoading(true);
    const data = {
      parkingSeriesDetailId: form?.parkingSeriesDetailId,
      communitySeriesDetailId: form?.communitySeriesDetailId || null,
      userId: form?.userId?.value || null,
    };
    api
      .allotParking(data)
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          toast.success("Alloted Successfully");
          cancelHandler();
        } else {
          toast.error(response?.data?.headers?.message);
          return;
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const userByRoleIdHandler = () => {
    // setLoading(true);
    api
      .getUserByRoleId(form?.roleId.value)
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          setUserList(response?.data?.body);
        } else {
          toast.error(response?.data?.headers?.message);
          return;
        }
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  const cancelHandler = () => {
    setUserDetails(null);
    setForm(null);
    setOpen(false);
  };

  return {
    getAmmenityDetailBySeriesDetailId,
    userDetails,
    setForm,
    setUserDetails,
    allotParkingHandler,
    userList,
    cancelHandler,
    form,
    open,
    setOpen,
  };
};

export default useAddOns;
