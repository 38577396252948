import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

const MiniLoader = () => {
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      {" "}
      <CircularProgress style={{ color: "var(--main-color)" }} />
    </div>
  );
};

export default MiniLoader;
