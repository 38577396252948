import { Box, Grid } from "@mui/material";
import React from "react";
import { HiPlusSm } from "react-icons/hi";
import MyButton from "../MyButton";
import MyInput from "../MyInput";
import MyPhoneCode from "../MyPhoneCode";
import MySelect from "../MySelect";
import classes from "./EditApprovalForm.module.css";
import MyCheckBox from "../MyCheckBox";

const EditApprovalForm = ({
  form,
  roleList,
  typeList,
  designationList,
  children,
  handleAccept,
  handleReject,
}) => {
  return (
    <div className={classes["formCardMainWrapper"]}>
      <div className={classes["header"]}>
        <p>Request Join Form</p>
        <HiPlusSm size={30} color="var(--black-color)" />
      </div>
      <Box
        component="form"
        onSubmit={form?.handleSubmit}
        sx={{
          "& .MuiTextField-root": { my: 1, ml: 0, width: "100%" },
        }}
        className={classes["formWrapper"]}
        noValidate
        autoComplete="off"
      >
        <MyInput
          name="name"
          label="Name"
          value={form?.values.name}
          setter={form.handleChange("name")}
          error={form.errors.name}
        />
        <Grid container columnGap={1}>
          <Grid xs={2.8}>
            <MyPhoneCode
              name="countryCode"
              label="C-Code"
              value={form?.values?.countryCode}
              setter={form.handleChange("countryCode")}
              error={form.errors.countryCode}
              disabled={true}
              readOnly={true}
            />
          </Grid>
          <Grid xs={9}>
            <MyInput
              type="number"
              name="cellNo"
              label="Number"
              value={form?.values?.cellNo}
              setter={form.handleChange("cellNo")}
              error={form.errors.cellNo}
              disabled={true}
              readOnly={true}
            />
          </Grid>
        </Grid>

        {typeList?.length > 0 && (
          <MySelect
            name="propertyTypeId"
            label="Type"
            value={form?.values?.propertyTypeId}
            setter={(values) => form.setFieldValue("propertyTypeId", values)}
            options={typeList}
            error={form.errors.propertyTypeId}
          />
        )}
        <MyCheckBox
          label={"Is Union Member"}
          value={form?.isUnionMember}
          setter={(e) => form.setFieldValue("isUnionMember", e.target.checked)}
        />
        {designationList?.length > 0 && (
          <MySelect
            name="designationId"
            label="Designation"
            value={form?.values?.designationId}
            setter={(values) => form.setFieldValue("designationId", values)}
            options={designationList.map((item) => ({
              label: item?.designationName,
              value: item?.designationId,
            }))}
            error={form.errors.designationId}
          />
        )}
        <MySelect
          name="role"
          label="User Access"
          value={form?.values?.role}
          setter={(value) => form.setFieldValue("role", value)}
          options={roleList.map((item) => ({
            label: item?.roleName,
            value: item?.roleId,
          }))}
          error={form.errors.role}
        />
        <div className={classes["btnWrapper"]}>
          <MyButton
            type="submit"
            handler={handleReject}
            btnText={"Reject"}
            className={classes["btnFormStylesReject"]}
          />
          <MyButton
            type="submit"
            handler={handleAccept}
            btnText={"Accept"}
            className={classes["btnFormStyles"]}
          />
        </div>
      </Box>
    </div>
  );
};

export default EditApprovalForm;
