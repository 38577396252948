import React, { useEffect, forwardRef, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./ShortcutItem.module.css";
import { selected } from "../../assets/constant";

const ShortcutItem = forwardRef(
  (
    { text, icon, path, className, active, setMarkAsActive, activeAction },
    ref
  ) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation()?.pathname.split("/")[1];
    const menuItem = useRef(null);
    const itemRef = useRef(null);
    const handleActive = () => {
      navigate(path);
      if (path == undefined) setMarkAsActive();
    };

    useEffect(() => {
      if (path == location) setMarkAsActive();
    }, [location]);

    return (
      <>
        <li ref={menuItem} className={[className].join(" ")}>
          <div
            ref={itemRef}
            className={[
              classes["menuItem"],
              classes["overflow-x-hidden"],
              active && classes.activeMenu,
            ].join(" ")}
            onClick={handleActive}
          >
            <img
              src={active && selected}
              className={[
                active
                  ? classes["activeImage"]
                  : activeAction === "up"
                  ? classes["hidden-down"]
                  : classes["hidden-up"],
              ].join(" ")}
            />
            <div
              className={[
                classes["menuContent"],
                classes["overflow-x-hidden"],
                location === path || active
                  ? classes["activeMenu"]
                  : activeAction === "up"
                  ? classes["hidden-down-shadow"]
                  : classes["hidden-up-shadow"],
              ].join(" ")}
            >
              <div className={classes["menuIcon-wrapper"]}>{icon}</div>
            </div>
          </div>
        </li>
      </>
    );
  }
);
export default ShortcutItem;
