import React from "react";
import classes from "./ComplainCard.module.css";
import MyButton from "../MyButton";

const ComplainCard = ({ data, handler, isSetting = false, ...props }) => {
  const {
    complainNo,
    date,
    userAddress,
    username,
    complainTitle,
    complainDescription,
  } = data;
  return (
    <div
      className={classes["mainWrapper"]}
      onClick={() => handler && handler(data)}
    >
      <div className={classes["firstDiv"]}>
        <p>CID {complainNo}</p>
        <p>{date}</p>
      </div>
      <div className={classes["userDetailsDiv"]}>
        <p>{userAddress}</p>
        <p>{username}</p>
      </div>
      <div className={classes["complainDetailsDiv"]}>
        <p>{complainTitle}</p>
        <p>{complainDescription}</p>
      </div>
      {isSetting && (
        <div className={classes["complainActionsDiv"]}>
          <MyButton
            className={classes["rejectBtn"]}
            handler={() => props.handleReject(complainNo)}
          >
            Reject
          </MyButton>
          <MyButton
            className={classes["acceptBtn"]}
            handler={() => props.handleAccept(complainNo)}
          >
            Accept in Progress
          </MyButton>
        </div>
      )}
    </div>
  );
};

export default ComplainCard;
