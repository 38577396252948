import React, { useEffect, useState } from "react";
import { BsChevronRight } from "react-icons/bs";
import { FaCircleArrowLeft } from "react-icons/fa6";
import { HiPlusSm } from "react-icons/hi";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DashboardCard from "../../../../components/DashboardCard";
import Loader from "../../../../components/Loader";
import PaddingContainer from "../../../../components/PaddingContainer";
import UserDetailsForm from "../../../../components/UserDetailsForm";
import useUnionMemberDetails from "../../../../Hooks/useUnionMemberDetails";
import UpdateAmenityApprovalModal from "../../../../modals/UpdateAmenityApprovalModal";
import UpdateUserAccessModal from "../../../../modals/UpdateUserAccessModal";
import classes from "./UsersDetailsPage.module.css";
import useLoader from "../../../../Hooks/useLoader";
import useUser from "../../../../Hooks/useUser";

const UsersDetailsPage = () => {
  const { loading } = useLoader();

  const { id } = useParams();
  const navigate = useNavigate();
  const user = useUser();
  const { roleList, seriesList, propertyTypeList, designationList } =
    useSelector((state) => state?.generalApiReducer);

  const recentHistoryHandler = () => {
    console.log("Recent History Button Clicked !");
  };

  const [openRoleModal, setOpenRoleModal] = useState(false);
  const [openPropertyModal, setOpenPropertyModal] = useState(false);

  const duesHanlder = () => {
    if (id !== 0) navigate(`/users/${id}/dues`);
  };

  useEffect(() => {
    if (+id !== 0) {
      user?.getUserDetailByUserDetailId(id);
    }
  }, [id]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {/* <div style={{ margin: "0px" }} className="c-p">
            <FaCircleArrowLeft
              color={"var(--main-color)"}
              size={40}
              onClick={() => navigate(-1)}
            />
          </div> */}
          <div
            className={[classes["orangeCardsWrapper"], "tabs-section"].join(
              " "
            )}
          >
            <DashboardCard
              count={"1000"}
              text={"Total Dues"}
              // handler={duesHanlder}
            />
            <DashboardCard count={"1000"} text={"Cash Received"} />
            <DashboardCard count={"1000"} text={"Total Expense"} />
            <DashboardCard count={"1000"} text={"Approvals"} />
          </div>
          {/* <Card
            role={user?.form?.values?.role?.label}
            handleClick={() => setOpenRoleModal(true)}
            error={user?.form?.errors}
          /> */}

          <Card1
            address={user?.form?.values?.communitySeriesDetailId?.label}
            handleClick={() => setOpenPropertyModal(true)}
            error={user?.form?.errors}
          />
          {/* <Card2 handleClick={() => {}} /> */}
          <UserDetailsForm
            form={user?.form}
            name="User Details"
            roleList={roleList}
            designationList={designationList}
            typeList={propertyTypeList}
          />
          {/* <MyButton
            className={classes["extraBtnStyle"]}
            handler={recentHistoryHandler}
          >
            <span className={classes["btnHeading"]}>Recent History</span>
            <FaChevronRight />
          </MyButton> */}
        </>
      )}
      {/* <UpdateUserAccessModal
        show={openRoleModal}
        setShow={() => setOpenRoleModal(false)}
        form={user?.form?.values}
        handleChange={(name, value) => user?.form.setFieldValue(name, value)}
        roleList={roleList}
      /> */}
      {/* <UpdateAmenityApprovalModal
        show={openPropertyModal}
        setShow={() => setOpenPropertyModal(false)}
        form={user.form}
        seriesList={seriesList}
      /> */}
    </>
  );
};

const Card = ({ error, role, handleClick }) => {
  return (
    <div className={classes["userDetailsWrapper"]} onClick={handleClick}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <div className={classes["userDetailsDiv"]}>
            <p>Role</p>
            <p>{role}</p>
          </div>
          {role == null && (
            <div className={classes["buttonDiv"]}>
              <p>Set User Access</p>
            </div>
          )}
        </div>
        {role ? (
          <BsChevronRight
            style={{
              float: "right",
              strokeWidth: "2px",
              color: "var(--black-color)",
            }}
          />
        ) : (
          <HiPlusSm size={30} color="var(--black-color)" />
        )}
      </div>
      <span style={{ color: "red", fontSize: "14px" }}>{error?.role}</span>
    </div>
  );
};

const Card1 = ({ address, handleClick, error }) => {
  return (
    <div className={classes["userDetailsWrapper"]} onClick={handleClick}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <div className={classes["userDetailsDiv"]}>
            <p>Address</p>
            <p>{address}</p>
          </div>
          {!address && (
            <div className={classes["buttonDiv"]}>
              <p>Set Property</p>
            </div>
          )}
        </div>
        {address?.length ? (
          <BsChevronRight
            style={{
              float: "right",
              strokeWidth: "2px",
              color: "var(--black-color)",
            }}
          />
        ) : (
          <HiPlusSm size={30} color="var(--black-color)" />
        )}
      </div>
      <span style={{ color: "red", fontSize: "14px" }}>
        {error?.communitySeriesDetailId}
      </span>
    </div>
  );
};

const Card2 = ({ handleClick }) => {
  return (
    <div className={classes["userDetailsWrapper"]} onClick={handleClick}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <div className={classes["userDetailsDiv"]}>
            <p>Parking allot</p>
          </div>
          <div className={classes["buttonDiv"]}>
            <p>Set Monthly Fee</p>
          </div>
        </div>
        <HiPlusSm size={30} color="var(--black-color)" />
      </div>
    </div>
  );
};

export default UsersDetailsPage;
