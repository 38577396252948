import React, { useEffect, useState } from "react";
import { FaCircleArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import useBooking from "../../../../../Hooks/useBooking";
import useLoader from "../../../../../Hooks/useLoader";
import AddButton from "../../../../../components/AddButton";
import BookingClientForm from "../../../../../components/BookingClientForm";
import BookingListing from "../../../../../components/BookingListing";
import FilterTabs from "../../../../../components/FilterTabs";
import Loader from "../../../../../components/Loader";
import classes from "./AmenitiesBookingPage.module.css";
import { MdCancel } from "react-icons/md";
import MyButton from "../../../../../components/MyButton";
import { RiDeleteBin6Line } from "react-icons/ri";
import EditButton from "../../../../../components/EditButton";
import DeleteButton from "../../../../../components/DeleteButton";
import MyCheckBox from "../../../../../components/MyCheckBox";
import AddBookingForm from "../../../../../components/AddBookingForm";
import {
  dateDifference,
  dateFromToFormatter,
  timeFromToFormatter,
} from "../../../../../helpers";

const AmenitiesBookingPage = () => {
  const { loading } = useLoader();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    bookingList,
    handleGetAllBookingListing,
    form,
    setSelectedItem,
    selectedItem,
    openAddNew,
    setOpenAddNew,
  } = useBooking();
  const [showSingleBooking, setShowSingleBooking] = useState(false);

  const tabs = [
    {
      name: "Ongoing",
      slug: "ongoing",
    },
    {
      name: "Upcoming",
      slug: "upcoming",
    },
    {
      name: "Cancel",
      slug: "cancel",
    },
    {
      name: "Completed",
      slug: "completed",
    },
  ];

  const [activeFilters, setActiveFilters] = useState({
    ongoing: true,
    upcoming: false,
    cancel: false,
    completed: false,
  });

  const handleFilterChange = (name) => {
    setActiveFilters((prev) => ({
      ...prev,
      [name]: !{ ...prev }[name],
    }));
  };

  const handleCardClick = (item) => {
    setSelectedItem(item);
    setShowSingleBooking(true);
    // navigate(`/amenityBookingDetails/${id}`)
  };

  const handleAddNewBooking = (item) => {
    setSelectedItem(null);
    form?.resetForm();
    setShowSingleBooking(true);
    setOpenAddNew(true);
    // navigate(`/amenityBookingDetails/${id}`)
  };

  useEffect(() => {
    handleGetAllBookingListing({ communitySeriesDetailId: +id, page: 10 });
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {!showSingleBooking ? (
            <div className={classes["mainWrapper"]}>
              <div className={classes["headerWrapper"]}>
                <div
                  className={[classes["filterWrapper"], "tabs-section"].join(
                    " "
                  )}
                >
                  <div style={{ marginTop: "8px" }} className="c-p">
                    <FaCircleArrowLeft
                      color={"var(--main-color)"}
                      size={40}
                      onClick={() => navigate(-1)}
                    />
                  </div>
                  <FilterTabs
                    tabs={tabs}
                    activeFilters={activeFilters}
                    handleFilterChange={handleFilterChange}
                  />
                </div>
                <AddButton handler={handleAddNewBooking} />
              </div>
              <BookingListing
                activeFilters={activeFilters}
                navigateHandler={(item) => handleCardClick(item)}
                title={"Bookings"}
                bookingData={bookingList}
              />
            </div>
          ) : (
            <>
              {openAddNew ? (
                <AddNewBooking
                  form={form}
                  goBackHandler={() => {
                    setShowSingleBooking(false);
                    setOpenAddNew(false);
                  }}
                />
              ) : (
                <PreviewBooking
                  form={form}
                  goBackHandler={() => setShowSingleBooking(false)}
                  selectedItem={selectedItem}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default AmenitiesBookingPage;

const AddNewBooking = ({ form, goBackHandler }) => {
  return (
    <>
      <div className={classes["headerCardWrapper"]}>
        <div style={{ marginTop: "0px" }} className="c-p">
          <FaCircleArrowLeft
            color={"var(--main-color)"}
            size={40}
            onClick={goBackHandler}
          />
        </div>
        <h1>AddNewBooking</h1>
      </div>
      <AddBookingForm form={form} />
      <BookingClientForm form={form} />
      <MyButton
        btnText={"Save"}
        className={classes["btnFormStyles"]}
        handler={form?.handleSubmit}
      />
    </>
  );
};

const PreviewBooking = ({ form, selectedItem, goBackHandler }) => {
  useEffect(() => {
    form?.setValues({
      cellNo: selectedItem.cellNo?.slice(3, 13),
      name: selectedItem?.name,
    });
  }, []);

  return (
    <>
      <div className={classes["headerCardWrapper"]}>
        <div style={{ marginTop: "0px" }} className="c-p">
          <MdCancel
            color={"var(--main-color)"}
            size={40}
            onClick={goBackHandler}
          />
        </div>
        <h1>PreviewBooking</h1>
      </div>
      <div className={classes["previewBodyWrapper"]}>
        <div className={classes["firstWrapper"]}>
          <div className={classes["actionsWrapper"]}>
            <MyCheckBox
              name={""}
              value={true}
              setter={() => {}}
              label={"Active"}
            />
          </div>
          <div className={classes["actionsWrapper"]}>
            <DeleteButton />
            <EditButton />
          </div>
        </div>
        <div className={classes["secondWrapper"]}>
          <p>Active</p>
          <p>
            {dateFromToFormatter(
              selectedItem?.fromDateTime,
              selectedItem?.toDateTime
            )}
          </p>
        </div>
        <div className={classes["thirdWrapper"]}>
          <p>{selectedItem?.eventTitle}</p>
          <p>
            {timeFromToFormatter(
              selectedItem?.fromDateTime,
              selectedItem?.toDateTime
            )}
          </p>
        </div>
      </div>
      <BookingClientForm form={form} name={"Client Info"} disabled={true} />
      <MyButton
        btnText={"Close"}
        className={classes["btnFormStyles"]}
        handler={goBackHandler}
      />
    </>
  );
};
