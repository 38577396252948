import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  activeCommunity,
  login,
  otpReducer,
  setCommunitiesList,
} from "../store/authSlice";
import useApi from "./useApi";
import useLoader from "./useLoader";

const useSignup = () => {
  const api = useApi();
  const { loading, setLoading } = useLoader();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [error, setError] = useState(null);
  const [steps, setSteps] = useState(0);
  const [otp, setOtp] = useState("");
  const [user, setUser] = useState({
    name: "",
    code: "+92",
    phoneNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
    termsNcond: false,
  });
  const phoneRegExp = /^(\(\+\d{1,3}\))?\s?\d{10}$/;

  const form = useFormik({
    initialValues: user,
    isInitialValid: false,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Required"),
      code: Yup.string().required("Required"),
      phoneNumber: Yup.string()
        .required("Required")
        .matches(phoneRegExp, "Phone number is not valid"),
      email: Yup.string()
        .email("Email Address is not valid")
        .required("Required"),
      termsNcond: Yup.string().required("Required"),
      password: Yup.string()
        .required("Password is required")
        .min(5, "Your password is too short."),
      confirmPassword: Yup.string()
        .required("Password is required")
        .oneOf([Yup.ref("password")], "Passwords must match"),
    }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      api
        .generateOtp({
          code: values?.code,
          phoneNumber: values?.phoneNumber,
        })
        .then((res) => {
          if (res?.data?.headers.code === 200) {
            dispatch(otpReducer(res?.data?.body));
            setUser(values);
            setSteps(1);
            toast.success("OTP has been sent Successfully");
          } else {
            toast.error(res?.data?.headers?.msg);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const verifyOtpHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    api
      .verifyOtp({
        otpCode: otp,
      })
      .then((res) => {
        if (res?.data?.headers.code === 200) {
          signUpHandler(res?.data?.body?.accessToken);
        } else {
          toast.error(res?.data?.headers?.msg);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const signUpHandler = async (token) => {
    api
      .signUp({
        name: user?.name,
        email: user?.email,
        code: user?.code,
        phoneNumber: user?.phoneNumber,
        password: user?.password,
        token,
      })
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          toast.success(response?.data?.body?.message);
          cancelHandler();
          dispatch(login(response?.data?.body));
          dispatch(activeCommunity(response?.data?.body.communityDetail[0]));
          dispatch(setCommunitiesList(response?.data?.body?.communityDetail));
          toast.success("User Register Successfully");
          navigate("/");
        } else {
          toast.error(response?.data?.headers?.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const cancelHandler = () => {
    setUser({
      name: "",
      code: "+92",
      phoneNumber: "",
      email: "",
      password: "",
      confirmPassword: "",
      termsNcond: false,
    });
    setOtp("");
    setError(false);
    setSteps(0);
  };

  return {
    otp,
    setOtp,
    error,
    form,
    user,
    steps,
    setSteps,
    verifyOtpHandler,
    signUpHandler,
    handleChange,
    signUpHandler,
  };
};

export default useSignup;
