import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import styles from "./SignUpCreate.module.css";
import AuthMainComponent from "../../../../components/AuthMainComponent";
import AuthSubComponent from "../../../../components/AuthSubComponent";
import MyInput from "../../../../components/MyInput";
import MyButton from "../../../../components/MyButton";
import MyCheckBox from "../../../../components/MyCheckBox";
import { useNavigate } from "react-router-dom";
import SignupStep1 from "./Step1";
import useSignup from "../../../../Hooks/useSignup";
import Step1 from "./Step1";
import Step3 from "./Step3";
import OTPScreen from "../OTPScreen";
import { loadingHandler } from "../../../../store/loadingSlice";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader";
import useLoader from "../../../../Hooks/useLoader";

export default function SignUpCreate() {
  const navigate = useNavigate();
  const signup = useSignup();
  const [error, setError] = useState(false);
  const { loading } = useLoader();

  const buttonHanler = () => {
    navigate("/");
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {signup.steps === 0 ? (
            <Grid
              container
              spacing={0}
              className={signup.steps === 1 && styles.parentWrapper}
            >
              <AuthMainComponent className={styles.otpStyles}>
                <Step1 buttonHanler={buttonHanler} signup={signup} />
              </AuthMainComponent>
              <AuthSubComponent
                text={"Already have an account? Login"}
                btnText={"Login"}
                btnFunc={buttonHanler}
              />
            </Grid>
          ) : (
            <OTPScreen
              otp={signup?.otp}
              handleChange={(val) => signup.setOtp(val)}
              handleSubmit={signup.verifyOtpHandler}
              handleFormSubmit={signup.signUpHandler}
              data={signup.form.values}
              error={error}
            />
          )}
        </>
      )}
    </>
  );
}
