import { useState, useEffect, useRef } from "react";
import useLoader from "./useLoader";

const useInfiniteScroll = (fetchMoreData, totalCount) => {
  const [page, setPage] = useState(3);
  const [isLoading, setIsLoading] = useState(false);
  const observerRef = useRef();
  const { loading, setLoading } = useLoader();

  useEffect(() => {
    setLoading(true);
    fetchMoreData({ page });
    setLoading(false);
  }, []);

  const handleObserver = (entries) => {
    const target = entries[0];
    if (target.isIntersecting && !isLoading) {
      setPage((prevPage) => prevPage + 3); // Increment page number
    }
  };

  useEffect(() => {
    // Set up the IntersectionObserver to watch the target element
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    });

    if (observerRef.current) observer.observe(observerRef.current);

    return () => {
      if (observerRef.current) observer.unobserve(observerRef.current);
    };
  }, [loading]);

  useEffect(() => {
    if (page > 3 && (totalCount === 0 || totalCount >= page)) {
      // Trigger the API call to load more data
      fetchMoreData({ page }).finally(() => {
        setLoading(false); // Stop the loading spinner after fetching
      });
    }
  }, [page]);

  return { observerRef, isLoading };
};

export default useInfiniteScroll;
