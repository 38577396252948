import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadingHandler } from "../store/loadingSlice";
import { toast } from "react-toastify";
import useApi from "./useApi";
import { useNavigate } from "react-router-dom";

const useUserRole = () => {
  const api = useApi();
  const dispatch = useDispatch();
  const { communityId } = useSelector((state) => state?.authReducer);
  const [roleListing, setRolesListing] = useState([]);
  const [modules, setModules] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [isUpdateEnable, setIsUpdateEnable] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState({ label: "10", value: 10 });
  const [form, setForm] = useState({
    roleId: 0,
    roleName: "",
    otherRole: "",
    active: true,
  });

  useEffect(() => {
    handleGetAllRoleListing();
  }, []);

  const handleGetAllRoleListing = async (
    pageNo = 1,
    limit = 10,
    search = "",
    sortField = "",
    sortDirection = "desc"
  ) => {
    const data = {
      pageNumber: 1,
      pageSize: 10,
      totalRowsCount: 0,
      sortField: "",
      sortDirection: "",
      search: "",
      filter: {
        communityId,
      },
    };
    dispatch(loadingHandler(true));
    api
      ?.GetAllRoleListing({ data })
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          setRolesListing(response?.data?.body?.data || []);
        } else {
          toast.error(response?.data?.headers?.message);
          return;
        }
      })
      .finally(() => {
        dispatch(loadingHandler(false));
      });
  };

  const handleGetAllModulesListing = async () => {
    dispatch(loadingHandler(true));
    api
      ?.GetAllRoleModules(communityId)
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          setModules(response?.data?.body || []);
        } else {
          toast.error(response?.data?.headers?.message);
          return;
        }
      })
      .finally(() => {
        dispatch(loadingHandler(false));
      });
  };

  const getRoleById = async (id) => {
    dispatch(loadingHandler(true));
    api
      ?.GetRoleById({ id })
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          setOpenAddModal(true);
          setModules(response?.data?.body?.permissionList);
          setForm({
            roleId: response?.data?.body?.roleId,
            roleName: response?.data?.body?.roleName,
            active: response?.data?.body?.active,
          });
          setIsUpdateEnable(true);
        } else {
          toast.error(response?.data?.headers?.message);
          return;
        }
      })
      .finally(() => {
        dispatch(loadingHandler(false));
      });
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    dispatch(loadingHandler(true));
    if (form?.roleName === "") {
      toast.error("Role name is required");
      dispatch(loadingHandler(false));
      return;
    }
    const data = {
      roleId: form?.roleId,
      roleName: isUpdateEnable
        ? form?.roleName
        : form?.roleName?.label === "Others"
        ? form?.otherRole
        : form?.roleName.label,
      active: form?.active,
      permissionList: modules,
    };

    const apiResponse = isUpdateEnable
      ? api?.UpdateRolePermission({ data })
      : api?.SaveRolePermission({ data });
    apiResponse
      ?.then((response) => {
        if (response?.data?.headers?.code === 200) {
          toast.success(
            isUpdateEnable
              ? "Role Updated successfully"
              : "Role Created Successfully"
          );
          cancelHandler();
          handleGetAllRoleListing();
        } else {
          toast.error(response?.data?.headers?.msg);
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        dispatch(loadingHandler(false));
        setOpenAddModal(false);
      });
  };

  const handleChange = ({ parentAccessId, selectedItem, subModuleId }) => {
    setModules((prev) =>
      prev.map((item) => {
        if (item.accessId !== parentAccessId) {
          return item;
        }

        const toggleItem = (list) => {
          const index = list.findIndex((i) => i.value === selectedItem.value);
          if (index > -1) {
            // Item exists, remove it
            return list.filter((i) => i.value !== selectedItem.value);
          } else {
            // Item does not exist, add it
            return [...list, selectedItem];
          }
        };

        if (subModuleId) {
          return {
            ...item,
            subModules: item.subModules.map((subItem) =>
              subItem.accessId === subModuleId
                ? {
                    ...subItem,
                    selectedApplicationAccessControlList: toggleItem(
                      subItem.selectedApplicationAccessControlList
                    ),
                  }
                : subItem
            ),
          };
        }
        return {
          ...item,
          selectedApplicationAccessControlList: toggleItem(
            item.selectedApplicationAccessControlList
          ),
        };
      })
    );
  };

  const selectAllHandler = ({ parentAccessId, subModuleId }) => {
    setModules((prev) =>
      prev.map((item) => {
        if (item.accessId !== parentAccessId) {
          return item;
        }
        const toggleItem = (list) => {
          const toggler =
            list.dbApplicationAccessControlList.length ===
            list.selectedApplicationAccessControlList.length;
          if (toggler) {
            return [];
          } else {
            return list?.dbApplicationAccessControlList;
          }
        };
        if (subModuleId) {
          return {
            ...item,
            subModules: item.subModules.map((subItem) =>
              subItem.accessId === subModuleId
                ? {
                    ...subItem,
                    selectedApplicationAccessControlList: toggleItem(subItem),
                  }
                : subItem
            ),
          };
        }
        return {
          ...item,
          selectedApplicationAccessControlList: toggleItem(item),
        };
      })
    );
  };

  const cancelHandler = () => {
    setOpenAddModal(false);
    setForm({
      roleId: 0,
      roleName: "",
      active: true,
    });
    setIsUpdateEnable(false);
    setModules([]);
  };

  return {
    roleListing,
    modules,
    form,
    setForm,
    isUpdateEnable,
    openAddModal,
    setOpenAddModal,
    handleGetAllModulesListing,
    getRoleById,
    submitHandler,
    cancelHandler,
    handleChange,
    selectAllHandler,
  };
};

export default useUserRole;
