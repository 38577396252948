import { Button } from "@mui/material";
import React, { Children } from "react";
import styles from "./MyButton.module.css";

const MyButton = ({
  btnText,
  className,
  type = "button",
  handler,
  disabled,
  children,
}) => {
  return (
    <>
      <Button
        variant="contained"
        type={type}
        className={[styles.btn, className].join(" ")}
        onClick={handler}
        disabled={disabled}
      >
        {btnText || children}
      </Button>
    </>
  );
};

export default MyButton;
