import React, { useState } from "react";
import classes from "./ServiceFieldCard.module.css";
import MyInput from "../../../../../../components/MyInput";
import { TrashIcon } from "../../../../../../assets/icons";
import AreYouSureModal from "../../../../../../modals/AreYouSure";
const ServiceFieldCard = ({
  service,
  values,
  touched,
  index,
  handleChangeWithTouch,
  onDelete,
  errors,
}) => {
  const [showAreYouSure, setShowAreYouSure] = useState(false);
  return (
    <>
      <li className={classes.fieldList}>
        <div className={classes.groupFields}>
          <ul>
            <li className={classes.wrapper}>
              <MyInput
                label="Service Name"
                setter={(e) => {
                  const updatedServices = [...values.services];
                  updatedServices[index] = {
                    ...updatedServices[index],
                    serviceName: e?.target?.value,
                  };
                  handleChangeWithTouch("services")(updatedServices);
                }}
                // error={errors?.services[index]?.serviceName}

                error={
                  touched?.services?.[index]?.serviceName &&
                  errors?.services?.[index]?.serviceName
                }
                value={service.serviceName} // Ensure to bind the current value
              />
            </li>
            <li className={classes.wrapper}>
              <MyInput
                label="Service Charges"
                setter={(e) => {
                  const updatedServices = [...values?.services];
                  updatedServices[index] = {
                    ...updatedServices[index],
                    serviceCharges: e?.target?.value,
                  };
                  handleChangeWithTouch("services")(updatedServices);
                }}
                error={
                  touched?.services?.[index]?.serviceCharges &&
                  errors?.services?.[index]?.serviceCharges
                }
                value={service.serviceCharges} // Ensure to bind the current value
              />
            </li>
          </ul>
          <div className={classes.ctaWrapoper}>
            <span
              className={classes.action}
              onClick={() => {
                // onDelete
                setShowAreYouSure(true);
              }}
            >
              <TrashIcon />
            </span>
          </div>
        </div>
      </li>

      <AreYouSureModal
        show={showAreYouSure}
        setShow={() => setShowAreYouSure(false)}
        handler={() => {
          onDelete();
        }}
      />
    </>
  );
};

export default ServiceFieldCard;
