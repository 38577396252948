import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { useState } from "react";
import { FaChevronRight } from "react-icons/fa";
import { RxCaretSort } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import MyButton from "../../../../components/MyButton";
import TabComponent from "../../../../components/TabComponent";
import ItemCard from "../ItemCard";
import classes from "./BillPage.module.css";

const BillPage = () => {
  const navigate = useNavigate();
  const [flatsData, setFlatsData] = useState([
    {
      id: 1,
      name: "Zone - A",
      flatNo: [
        { no: "301", selected: true },
        { no: "302", selected: false },
        { no: "303", selected: false },
        { no: "304", selected: false },
        { no: "305", selected: false },
        { no: "306", selected: false },
        { no: "307", selected: false },
        { no: "308", selected: false },
        { no: "309", selected: false },
        { no: "310", selected: false },
      ],
    },
  ]);

  const selectHandler = (id, flatno) => {
    setFlatsData((prevFlatsData) =>
      prevFlatsData.map((flat) => {
        if (flat.id === id) {
          return {
            ...flat,
            flatNo: flat.flatNo.map((f) =>
              f.no === flatno ? { ...f, selected: !f.selected } : f
            ),
          };
        }
        return flat;
      })
    );
  };
  const handleSelectAllChange = (e) => {
    const { checked } = e.target;
    setFlatsData((prevFlatsData) =>
      prevFlatsData.map((flat) => ({
        ...flat,
        flatNo: flat.flatNo.map((f) => ({ ...f, selected: checked })),
      }))
    );
  };

  return (
    <div className={classes["mainWrapper"]}>
      <div className={classes["searchWrapper"]}>
        <DatePicker label="Select Date" />
      </div>
      <div className={classes["tabs-section"]}>
        <TabComponent text={"Blocks"} isOpen={true} />
        <TabComponent text={"Floors"} isOpen={true} />
        <TabComponent text={"Flats"} isOpen={true} />
        <TabComponent text={"Zones"} isOpen={true} />
      </div>
      <div className={classes["subSection"]}>
        <div className={classes["sub-heading"]}>
          <h1>Flats</h1>
          {flatsData?.length > 1 && (
            <p>
              Sorting <RxCaretSort size={20} style={{ strokeWidth: "1px" }} />
            </p>
          )}
        </div>
        <ItemCard
          data={flatsData}
          handler={selectHandler}
          selectAll={handleSelectAllChange}
        />
      </div>
      <MyButton
        className={classes["extraBtnStyle"]}
        handler={() => navigate("/payment&bills/details")}
      >
        <span className={classes["btnHeading"]}>Generate maintenance</span>
        <FaChevronRight />
      </MyButton>
    </div>
  );
};

export default BillPage;
