import React, { useState } from "react";
import { Grid } from "@mui/material";
import MyInput from "../MyInput";
import { FaCheck } from "react-icons/fa";
import styles from "./ExpenseAddComponent.module.css";
import { toast } from "react-toastify";

const ExpenseAddComponent = ({ form, handleChange, handleAddNew }) => {
  const [showAddNew, setShowAddNew] = useState(false);
  return (
    <div className={""}>
      {showAddNew && (
        <Grid
          container
          columnGap={1}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Grid xs={5}>
            <MyInput
              name={`expenseName`}
              label="Expense Name"
              value={form?.expenseName}
              setter={(e) => handleChange(e)}
            />
          </Grid>
          <Grid xs={4}>
            <MyInput
              name="amount"
              label="Amount"
              value={form?.amount}
              setter={(e) => handleChange(e)}
            />
          </Grid>
          <Grid xs={2}>
            <FaCheck
              color="var(--secondary-color)"
              size={40}
              className={[styles.deleteBtn, "c-p"].join(" ")}
              onClick={() => {
                if (form?.expenseName !== "") {
                  handleAddNew();
                } else {
                  toast.error("Expense name is required");
                }
              }}
            />
          </Grid>
        </Grid>
      )}

      <div className={styles.addNewWrapper}>
        <span
          className={styles.addNew}
          onClick={() => setShowAddNew(!showAddNew)}
        >
          {showAddNew ? "Close" : "Add New"}
        </span>
        <span>10 Records</span>
      </div>
    </div>
  );
};

export default ExpenseAddComponent;
