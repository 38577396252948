import React, { useEffect, useState } from "react";
import classes from "./BillsDetailsPage.module.css";
import { FaCircleArrowLeft } from "react-icons/fa6";
import SearchBox from "../../../../components/SearchBox";
import MySelect from "../../../../components/MySelect";
import TabComponent from "../../../../components/TabComponent";
import { RxCaretSort } from "react-icons/rx";
import ItemCard from "../ItemCard";
import { useNavigate } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FaChevronRight } from "react-icons/fa";
import MyButton from "../../../../components/MyButton";
import DashboardCard from "../../../../components/DashboardCard";

const BillsDetailsPage = () => {
  const navigate = useNavigate();
  return (
    <div className={classes["mainWrapper"]}>
      <div style={{ margin: "0px" }} className="c-p">
        <FaCircleArrowLeft
          color={"var(--main-color)"}
          size={40}
          onClick={() => navigate(-1)}
        />
      </div>
      <div className={classes["searchWrapper"]}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker label="Select Date" />
        </LocalizationProvider>
      </div>
      <div className={classes["tabs-section"]}>
        <TabComponent text={"Blocks"} isOpen={true} />
        <TabComponent text={"Floors"} isOpen={true} />
        <TabComponent text={"Flats"} isOpen={true} />
        <TabComponent text={"Zones"} isOpen={true} />
      </div>
      <div className={classes["orangeCardsWrapper"]}>
        <DashboardCard count={"20/0"} text={"Total Bills"} />
        <DashboardCard count={"24,000"} text={"Cash Collected"} />
        <DashboardCard count={"10,000"} text={"Remaining Dues"} />
        <DashboardCard count={"80,000"} text={"Total Remaining Dues"} />
      </div>
      <Card
        name={"Haris Ali"}
        address={"Flat/301 - Block A - 1st Floor"}
        handleClick={() => {
          navigate("/payment&bills/details/301");
        }}
      />
      <Card
        name={"Muhammad Owais"}
        address={"Flat/302- Block A - 1st Floor"}
        handleClick={() => {
          navigate("/payment&bills/details/302");
        }}
      />
    </div>
  );
};

export default BillsDetailsPage;

const Card = ({ name, address, handleClick }) => {
  return (
    <div className={classes["cardMainWrapper"]}>
      <div className={classes["userDetailsWrapper"]}>
        <div className={classes["detailsWrapper"]}>
          <div className={classes["userDetailsDiv"]}>
            <p>{address}</p>
            <p>{name}</p>
          </div>
        </div>
        <FaChevronRight size={20} />
      </div>
      <MyButton
        btnText={"Collect"}
        className={classes["extraBtnStyles"]}
        handler={handleClick}
      />
    </div>
  );
};
