import { LuInfo } from "react-icons/lu";
import MyButton from "../../components/MyButton";
import Classes from "./UpdateAmenityApprovalModal.module.css";
import Modal from "@mui/material/Modal";
import React from "react";
import MySelect from "../../components/MySelect";
import MyInput from "../../components/MyInput";
import MySearchSelect from "../../components/MySearchSelect";

const UpdateAmenityApprovalModal = ({
  show,
  setShow,
  form,
  seriesList,
  handler,
  title = "Update Amenity Details",
}) => {
  return (
    <>
      <style>{`
      .modal-sm {
        --bs-modal-width: 350px;
      }
      .modal-content{
        border-radius: 16px;
      }
    `}</style>
      <Modal
        open={show}
        onClose={setShow}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <div className={Classes.container}>
          <div className={Classes.description}>
            <p>{title}</p>
          </div>
          <div className={Classes.inputWrapper}>
            <MySelect
              name="communitySeriesDetailId"
              label="Select Property"
              value={form?.values?.communitySeriesDetailId}
              setter={(value) =>
                form.setFieldValue("communitySeriesDetailId", value)
              }
              options={seriesList.map((item) => ({
                value: item?.communitySeriesDetailId,
                label: `${item?.ammenityName} ${item?.prefix}-${item?.unitName}`,
              }))}
              error={form.errors.communitySeriesDetailId}
            />
          </div>

          <div className={Classes.actionsWrapper}>
            <MyButton
              btnText={"Update"}
              handler={() => {
                setShow(false);
                if (typeof handler == "function") {
                  handler();
                }
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UpdateAmenityApprovalModal;
