import React, { useState } from "react";
import classes from "./Parking.module.css";
import SearchBox from "../../../components/SearchBox";
import MyButton from "../../../components/MyButton";
import { FaChevronRight } from "react-icons/fa";
import { RxCaretSort } from "react-icons/rx";
import TabComponent from "../../../components/TabComponent";
import ItemCard from "./ItemCard";
import Header from "../../../components/Header";
import PaddingContainer from "../../../components/PaddingContainer";
import ParkingAllotedBSheet from "./ParkingAllotedBSheet";
import ParkingAllotmentBSheet from "./ParkingAllotmentBSheet";
import AllotParkingModal from "../../../modals/AllotParkingModal";
import { useParams } from "react-router-dom";
import useLoader from "../../../Hooks/useLoader";

const Parking = () => {
  const { id } = useParams();
  const { loading } = useLoader();

  const [open, setOpen] = useState(false);
  const [allotedOpen, setAllotedOpen] = useState(false);
  const [allotedData, setAllotedData] = useState(null);

  const [form, setForm] = useState({
    parkingType: "",
    parkingName: "",
  });

  const handleChange = (name, value) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleAllotedChange = (val) => {
    setAllotedData((prev) => ({ ...prev, propertyType: val }));
  };

  const toggleDrawer = (data, key) => {
    if (key) {
      setAllotedData({
        type: data?.type,
        name: data?.name,
        flatNo: data?.no,
        address: data?.address,
      });
      setAllotedOpen(true);
    } else {
      setOpen(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    setAllotedOpen(false);
  };

  return (
    <>
      <AllotParkingModal setShow={() => setOpen(false)} show={open}>
        <ParkingAllotmentBSheet
          open={open}
          setOpen={setOpen}
          form={form}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />
      </AllotParkingModal>
    </>
  );
};

export default Parking;

// <ParkingAllotedBSheet
//           open={allotedOpen}
//           setOpen={setAllotedOpen}
//           form={allotedData}
//           handleChange={handleAllotedChange}
//           handleSubmit={handleSubmit}
//         />
