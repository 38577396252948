import * as React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Box, Grid } from "@mui/material";
import MyInput from "../MyInput";
import { MdCancel } from "react-icons/md";
import MySelect from "../MySelect";
import MyButton from "../MyButton";
import classes from "./DrawerWrapper.module.css";

const DrawerWrapper = ({
  open,
  setOpen,
  headingText,
  btnText,
  handleSubmit,
  children,
}) => {
  return (
    <>
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
          sx: { width: "50%" },
        }}
      >
        <div className={classes["mainWrapper"]}>
          <div
            style={{
              margin: "0px",
              display: "flex",
              alignItems: "center",
              gap: "20px",
            }}
            className="c-p"
          >
            <MdCancel
              color={"var(--main-color)"}
              size={40}
              onClick={() => setOpen(false)}
            />
            <h1 style={{ fontSize: "18px", fontWeight: "700" }}>
              {headingText}
            </h1>
          </div>
          {children}
          {btnText && (
            <div className={classes["btnWrapper"]}>
              <MyButton
                btnText={btnText}
                handler={handleSubmit}
                className={classes["extraBtnStyles"]}
              />
            </div>
          )}
        </div>
      </SwipeableDrawer>
    </>
  );
};

export default DrawerWrapper;
