import React from "react";
import classes from "./ExpenseDetailCard.module.css";
import { BsChevronRight } from "react-icons/bs";
import { FaChevronRight } from "react-icons/fa";
import MyButton from "../MyButton";
import ExpenseAddComponent from "../ExpenseAddComponent";

const ExpenseDetailCard = ({
  data,
  singleExpenseHandler,
  form,
  handleChange,
  handleAddNew,
}) => {
  const { id, name, expenseList } = data;
  return (
    <div className={classes["expenseWrapper"]}>
      <h1>{name}</h1>
      <div className={classes["expenseList"]}>
        {expenseList?.map((item, index) => (
          <div
            className={classes["singleExpense"]}
            onClick={(e) => singleExpenseHandler(e, id)}
          >
            <h2>{item?.expenseName}</h2>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <h2>{item?.amount}</h2>
              <BsChevronRight
                size={15}
                style={{
                  strokeWidth: "2px",
                  color: "var(--main-color)",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        ))}
      </div>
      <ExpenseAddComponent
        form={form}
        handleChange={handleChange}
        handleAddNew={handleAddNew}
      />
      <MyButton className={classes["extraBtnStyle"]} handler={() => {}}>
        <span className={classes["btnHeading"]}>Add Expense</span>
        <FaChevronRight />
      </MyButton>
    </div>
  );
};

export default ExpenseDetailCard;
