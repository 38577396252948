import { Box, FormControlLabel, Radio, RadioGroup, Grid } from "@mui/material";
import React from "react";
import MyInput from "../../../../../components/MyInput";
import { RiDeleteBin6Line } from "react-icons/ri";
import AmenityAddComponent from "../../../../../components/AmmenityAddComponent";
import styles from "./EditAmenity.module.css";
import MyButton from "../../../../../components/MyButton";

const EditAmenity = ({
  form,
  handleChange,
  handleAddSeries,
  handleRemoveSeries,
  handleSubmit,
  cancelHandler,
}) => {
  return (
    <>
      {form && (
        <Box
          sx={{
            "& .MuiTextField-root": { my: 1, ml: 0, width: "100%" },
          }}
          className={styles.formComponent}
        >
          {/* {amenitiesArr.map((item, index) => ( */}
          <AmenityAddComponent
            item={form}
            handleChange={handleChange}
            handleAddSeries={handleAddSeries}
            handleRemoveSeries={handleRemoveSeries}
            styles={styles}
          />
          {/* ))} */}
          <div className={styles.btnWrapper}>
            <MyButton
              btnText={"Cancel"}
              handler={cancelHandler}
              className={styles.btn}
            />
            <MyButton
              btnText={"Update"}
              handler={handleSubmit}
              className={styles.btn}
            />
          </div>
        </Box>
      )}
    </>
  );
};

export default EditAmenity;
