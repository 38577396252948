import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import {
  dataURLtoFile,
  fileDownloadFunction,
  filterCompleteCommunityList,
} from "../../helpers";
import useApi from "../../Hooks/useApi";
import useLoader from "../../Hooks/useLoader";
import UploadFile from "../../modals/UploadFile";
import UploadResponse from "../../modals/UploadResponse";
import {
  activeCommunity,
  setCommunitiesList,
  setIsSkip,
  setPendingCommunityList,
} from "../../store/authSlice";
import styles from "./OnBoarding.module.css";
import SignUpInitialStep from "./SignUpInitialStep";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";

const OnBoarding = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, setLoading } = useLoader();
  const { accessToken, pendingCommunityList, communitiesList } = useSelector(
    (state) => state?.authReducer
  );

  // const identifySteps = communityId === 18 ? (isCommunity === 3 ? 1 : 0) : 2;
  const identifySteps =
    pendingCommunityList === null || communitiesList?.length > 1 ? 0 : 2;
  const api = useApi();

  const [steps, setSteps] = useState(identifySteps);
  const [communityCode, setCommunityCode] = useState("");
  const [communityDetails, setCommunityDetails] = useState(null);
  const [error, setError] = useState(false);
  const [amenitiesArrList, setAmenitiesArrList] = useState([]);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [previewList, setPreviewList] = useState([]);
  const [visible, setVisible] = useState(false);

  const [form, setForm] = useState({
    communityName: pendingCommunityList?.communityName,
    communityAddress: "",
    communityAmmenityList: pendingCommunityList?.communityAmmenityList || [],
  });

  const goBackHandler = () => {
    if (steps === 3) {
      setSteps(1);
    } else {
      setSteps((prev) => prev - 1);
    }
    setError(false);
  };

  const downloadHandler = async () => {
    setLoading(true);
    api
      .DownloadAmmenitySeriesExcel()
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          const { fileContents, contentType, fileDownloadName } =
            response.data.body;
          const data = dataURLtoFile(fileContents, contentType);
          fileDownloadFunction(fileDownloadName, data, contentType);

          toast.success("File Downloaded Successfully.");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const uploadHandler = async (formData) => {
    setLoading(true);
    api
      .UploadAmmenitySeriesExcel({ formData })
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          toast.success("File uploaded Successfully.");
          handleCompleteManually();
        } else {
          setErrorMsg(response?.data?.headers?.msg);
          const { fileContents, contentType, fileDownloadName } =
            response.data.body;
          const data = dataURLtoFile(fileContents, contentType);
          fileDownloadFunction(fileDownloadName, data, contentType);
          setShowWarningModal(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    setShowUploadModal(false);
  };

  const saveCommunityHandler = async () => {
    setLoading(true);
    const data = {
      communityName: form?.communityName,
      communityAddress: form?.communityAddress,
    };
    api
      .SaveCommunityDetails(data)
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          const updatedData = {
            communityId: response?.data?.body?.communityID,
            communityName: form?.communityName,
          };
          dispatch(setPendingCommunityList(updatedData));
          setSteps(2);
        } else {
          toast.error(response?.data?.headers?.msg);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submitHandler = async () => {
    setLoading(true);
    const data = {
      ...form,
      communityId: pendingCommunityList?.communityId,
    };
    api
      .SaveAmmenityRanges(data)
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          setPreviewList(response?.data?.body);
          setCommunityDetails(response?.data?.body?.communityDetail[0]);
          toast.success(response?.data?.headers?.msg);
          setSteps(4);
        } else {
          toast.error(response?.data?.headers?.msg);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAllAmenities = async () => {
    setLoading(true);
    api
      .GetAllAmmenities()
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          setAmenitiesArrList(response?.data?.body);
        } else {
          toast.error(response?.data?.headers?.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCompleteManually = () => {
    dispatch(activeCommunity(communityDetails));
    dispatch(setCommunitiesList([...communitiesList, communityDetails]));
    dispatch(setPendingCommunityList(null));
  };

  const handleLayoutPreview = () => {
    dispatch(
      setPendingCommunityList({
        ...pendingCommunityList,
        communityAmmenityList: form?.communityAmmenityList,
      })
    );
    const issues = form?.communityAmmenityList.some((item) => {
      if (!item.isWithSeries) {
        return (
          item?.communityAmmenitySeriesList?.filter(
            (e) => !e?.name || e?.name.trim() === ""
          ).length > 0
        );
      } else {
        return (
          item?.communityAmmenitySeriesList?.filter(
            (e) =>
              !e?.prefix ||
              e?.prefix.trim() === "" ||
              !e?.seriesStart ||
              e?.seriesStart.trim() === "" ||
              !e?.seriesEnd ||
              e?.seriesEnd.trim() === ""
          ).length > 0
        );
      }
    });
    if (issues) {
      toast.error("Please fill all the fields");
      return;
    }
    setSteps(3);
  };

  const stepsHandler = async (step) => {
    setTimeout(() => {
      setVisible(true);
      setSteps(step);
    }, 300);
  };

  useEffect(() => {
    setVisible(true);
  }, []);

  useEffect(() => {
    getAllAmenities();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const components = () => {
    if (steps === 0) {
      // initial create/join
      return (
        <SignUpInitialStep
          createCommunityHandler={() => {
            setVisible(false);
            stepsHandler(1);
          }}
          continueCommunityHandler={() => {
            setVisible(false);
            stepsHandler(2);
          }}
          joinCommunityHandler={() => {
            // setVisible(false);
            // stepsHandler(5);
          }}
          skipHandler={() => {
            dispatch(
              activeCommunity(filterCompleteCommunityList(communitiesList))
            );
            dispatch(setIsSkip(true));
          }}
        />
      );
    } else if (steps === 1) {
      // name and address
      return (
        <Step1
          form={form}
          loading={loading}
          handleChange={handleChange}
          handleSubmit={saveCommunityHandler}
          goBackHandler={() => {
            setVisible(false);
            stepsHandler(0);
          }}
        />
      );
    } else if (steps === 2) {
      // add amenity layout
      return (
        <Step2
          form={form}
          setForm={setForm}
          error={error}
          handleSubmit={handleLayoutPreview}
          amenitiesArrList={amenitiesArrList}
          skipHandler={() => {
            dispatch(
              activeCommunity(filterCompleteCommunityList(communitiesList))
            );
            dispatch(setIsSkip(true));
          }}
        />
      );
    } else if (steps === 3) {
      // preview Layout
      return (
        <Step3
          goBackHandler={() => {
            setVisible(false);
            stepsHandler(2);
          }}
          form={form}
          loading={loading}
          handleSubmit={submitHandler}
        />
      );
    } else if (steps === 4) {
      // download/upload
      return (
        <Step4
          downloadHandler={downloadHandler}
          uploadHandler={() => setShowUploadModal(true)}
          manuallyHandler={handleCompleteManually}
          goBackHandler={() => {
            setVisible(false);
            stepsHandler(2);
          }}
          error={error}
          loading={loading}
        />
      );
    } else if (steps === 5) {
      // join community
      return <></>;
    }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Grid
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={[
              styles.wrapper,
              visible ? styles.visible : styles.hidden,
            ].join(" ")}
          >
            {components()}
          </Grid>
          <UploadFile
            show={showUploadModal}
            setShow={() => setShowUploadModal(false)}
            handler={uploadHandler}
          />
          <UploadResponse
            show={showWarningModal}
            setShow={() => setShowWarningModal(false)}
            handler={handleCompleteManually}
            errorMsg={errorMsg}
          />
        </>
      )}
    </>
  );
};

export default OnBoarding;
