import React, { useEffect, useState } from "react";
import classes from "./MemberBillDetails.module.css";
import { FaCircleArrowLeft } from "react-icons/fa6";
import SearchBox from "../../../../components/SearchBox";
import MySelect from "../../../../components/MySelect";
import TabComponent from "../../../../components/TabComponent";
import { RxCaretSort } from "react-icons/rx";
import ItemCard from "../ItemCard";
import { useNavigate, useParams } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FaChevronRight } from "react-icons/fa";
import MyButton from "../../../../components/MyButton";
import DashboardCard from "../../../../components/DashboardCard";
import MaintenanceDetailsCard from "../MaintenanceDetailsCard";
import { Box } from "@mui/material";
import MyInput from "../../../../components/MyInput";

const MemberBillDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [received, setReceived] = useState(false);
  const [maintenanceData, setMaintenanceData] = useState({
    maintenance: 2000,
    penalty: 400,
    maintenanceMonth: ["Jan", "Feb"],
  });
  const [receivedData, setReceivedData] = useState({
    receivedBy: "Saad",
    receivedMaintenance: 0,
  });

  return (
    <div className={classes["mainWrapper"]}>
      <div style={{ margin: "0px" }} className="c-p">
        <FaCircleArrowLeft
          color={"var(--main-color)"}
          size={40}
          onClick={() => navigate(-1)}
        />
      </div>
      <Card
        name={"Haris Ali"}
        address={"Flat/301 - Block A - 1st Floor"}
        handleClick={() => {}}
      />
      <MaintenanceDetailsCard
        data={maintenanceData}
        receivedData={receivedData}
        isReceived={received}
      />
      {!received && (
        <Box
          component="form"
          // onSubmit={handleSubmit}
          sx={{
            "& .MuiTextField-root": { my: 1, ml: 0, width: "100%" },
          }}
          className={classes["formWrapper"]}
          noValidate
          autoComplete="off"
        >
          <MyInput
            name="receivedMaintenance"
            label="Received Amount"
            value={receivedData?.receivedMaintenance}
            setter={(e) =>
              setReceivedData((prev) => ({
                ...prev,
                receivedMaintenance: e.target.value,
              }))
            }
          />
        </Box>
      )}
      {!received ? (
        <MyButton
          className={classes["extraBtnStyle"]}
          handler={() => setReceived(true)}
        >
          Recieved
          <FaChevronRight />
        </MyButton>
      ) : (
        <MyButton
          className={classes["extraBtnStyle"]}
          handler={() => setReceived(true)}
        >
          Download
          <span
            className={classes["btnHeading"]}
            onClick={() => navigate("/payment&bills/details")}
          >
            Okay
          </span>
        </MyButton>
      )}
    </div>
  );
};

export default MemberBillDetails;

const Card = ({ name, address, handleClick }) => {
  return (
    <div className={classes["cardMainWrapper"]} onClick={handleClick}>
      <div className={classes["detailsWrapper"]}>
        <div className={classes["userDetailsDiv"]}>
          <p>{address}</p>
          <p>{name}</p>
        </div>
      </div>
      <FaChevronRight size={20} />
    </div>
  );
};
