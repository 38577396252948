import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useApi from "./useApi";
import useLoader from "./useLoader";
import useInfiniteScroll from "./useInfiniteScroll";
import { useSelector } from "react-redux";

const useUserAccess = () => {
  const api = useApi();
  const { communityId } = useSelector((state) => state?.authReducer);

  const { loading, setLoading } = useLoader();
  const [userListing, setUserListing] = useState([]);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState({ label: "10", value: 10 });
  const [form, setForm] = useState({
    name: "",
    username: "",
    role: null,
  });

  // useEffect(() => {
  //   handleGetAllUserListing();
  // }, []);

  const handleGetAllUserListing = async ({
    pageNo = 1,
    limit = 10,
    search = "",
    sortField = "",
    sortDirection = "desc",
    page,
  }) => {
    const data = {
      pageNumber: 1,
      pageSize: page,
      totalRowsCount: 0,
      sortField: "",
      sortDirection: "",
      search: "",
      filter: {
        propertyTypeId: 0,
        roleId: 0,
        communityId,
      },
    };
    // setLoading(true);
    const result = await api?.GetAllUserListing(data);
    if (result?.data?.headers?.code === 200) {
      setUserListing(result?.data?.body?.data);
      setTotalCount(result?.data?.body?.pagination?.totalRowsCount);
    } else {
      toast.error(result?.data?.headers?.message);
    }
    // setLoading(false);
  };

  const { observerRef, isLoading } = useInfiniteScroll(
    handleGetAllUserListing,
    totalCount
  );

  const setUserHandler = async (item) => {
    // if (item?.ammenitiesDetail.length > 0) {

    // } else {
    //   toast.warn("Please assign property first");
    // }
    setForm({
      name: item?.name,
      userDetailId: item?.userDetailID,
      role: {
        label: item?.roleName,
        value: item?.roleID,
      },
    });
    setOpenUpdate(true);
  };

  const submitHandler = async () => {
    setLoading(true);
    const apiResponse = await api?.UpdateUserAccess({
      communityId,
      userDetailId: form?.userDetailId,
      roleId: form?.role?.value,
    });
    if (apiResponse?.data?.headers?.code === 200) {
      toast.success("User Updated successfully");
      cancelHandler();
      handleGetAllUserListing();
    } else {
      toast.error(apiResponse?.data?.headers?.msg);
    }
    setLoading(false);
  };

  const cancelHandler = () => {
    setOpenUpdate(false);
    setForm({
      name: "",
      userDetailId: "",
      role: null,
    });
  };

  return {
    form,
    setForm,
    userListing,
    openUpdate,
    setOpenUpdate,
    setUserHandler,
    submitHandler,
    cancelHandler,
    observerRef,
    isLoading,
  };
};

export default useUserAccess;
