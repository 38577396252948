import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { logoutIcon1 } from "../../../assets/constant";
import LogoutModal from "../../../modals/Logout";
import { logout } from "../../../store/authSlice";
import Shortcuts from "../Shortcuts";
import classes from "./ParentSkeleton.module.css";

const ParentSkeleton = ({ children }) => {
  const sidebarAside = useRef(null);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const logoutHandler = () => {
    dispatch(logout());
    setShowModal(false);
  };

  return (
    <>
      <div className={classes["sidebar__wrapper"]}>
        <Shortcuts setIsSidebarClose={() => {}} />
        <div
          className={[
            classes["down-actions-wrapper"],
            classes["down-actions-sb-close"],
          ].join(" ")}
        >
          <div
            className={classes["logout-wrapper"]}
            onClick={() => setShowModal(true)}
          >
            <img src={logoutIcon1} />
          </div>
        </div>
        <div className={[classes["sidebar__mainContent"]].join(" ")}>
          {children}
        </div>
      </div>
      <LogoutModal
        setShow={() => setShowModal(false)}
        show={showModal}
        handler={logoutHandler}
      />
    </>
  );
};

export default ParentSkeleton;
