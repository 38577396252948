import React from "react";
import { BsChevronDown, BsChevronRight } from "react-icons/bs";
import classes from "./FilterButton.module.css";
const FilterButton = ({ text, active = false, onClick }) => {
  return (
    <div
      className={[classes["mainWrapper"], active && classes["active"]].join(
        " "
      )}
      onClick={onClick}
    >
      <p>{text}</p>
    </div>
  );
};

export default FilterButton;
