import React, { useState, useEffect } from "react";
import PaddingContainer from "../../../components/PaddingContainer";
import Header from "../../../components/Header";
import AddButton from "../../../components/AddButton";
import FilterTabs from "../../../components/FilterTabs";
import UserListing from "../../../components/UserListing";
import { useNavigate } from "react-router-dom";
import classes from "./UsersPage.module.css";
import SearchBox from "../../../components/SearchBox";
import UsersDetailsPage from "./UsersDetailsPage";
import useUnionMemberDetails from "../../../Hooks/useUnionMemberDetails";
const UsersPage = () => {
  const navigate = useNavigate();
  const user = useUnionMemberDetails();
  const [addOrEdit, setAddOrEdit] = useState(false);

  const navigateHandler = (item) => {
    navigate(`/user/${item?.userDetailID}`);
  };

  const addNewHandler = () => {
    navigate("/user/0");
  };

  const tabs = [
    {
      name: "President",
      slug: "president",
    },
    {
      name: "Owner",
      slug: "owner",
    },
    {
      name: "Tenant",
      slug: "tenant",
    },
    {
      name: "Contact",
      slug: "contact",
    },
  ];

  const [activeFilters, setActiveFilters] = useState({
    president: true,
    tenant: false,
    owner: false,
    contact: false,
  });

  const handleFilterChange = (name) => {
    setActiveFilters((prev) => ({
      ...prev,
      [name]: !{ ...prev }[name],
    }));
  };

  return (
    <>
      {!addOrEdit ? (
        <>
          <Header title={"Users"}>
            <AddButton handler={addNewHandler} />
          </Header>
          <FilterTabs
            tabs={tabs}
            activeFilters={activeFilters}
            handleFilterChange={handleFilterChange}
          />
          <UserListing
            title={"Users"}
            memberData={user?.userListing}
            activeFilters={activeFilters}
            navigateHandler={navigateHandler}
          />
        </>
      ) : (
        <UsersDetailsPage />
      )}
    </>
  );
};

export default UsersPage;
