import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import { IoSearchOutline } from "react-icons/io5";

const SearchBox = ({ label, value, name, setter, error, width = "100%" }) => {
  return (
    <TextField
      id="outlined-basic"
      name={name}
      label={label}
      value={value}
      onChange={setter}
      variant="outlined"
      size="medium"
      className="custom-textfield"
      sx={{ width: width }}
      error={error}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IoSearchOutline size={25} color="var(--black-color)" />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchBox;
