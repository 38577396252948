import React from "react";
import classes from "./EditButton.module.css";
import { FiEdit } from "react-icons/fi";

const EditButton = ({ handler }) => {
  return (
    <div className={[classes.addMenuBtn, "c-p"].join(" ")} onClick={handler}>
      <FiEdit color="var(--secondary-color)" size={20} />
    </div>
  );
};

export default EditButton;
