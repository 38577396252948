import useApi from "./useApi";
import * as Yup from "yup";
import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelectors } from "react-redux";
import { community, login } from "../store/authSlice";
import { loadingHandler } from "../store/loadingSlice";
import { toast } from "react-toastify";
import useLoader from "./useLoader";

const useMember = () => {
  const api = useApi();
  const { communityId } = useSelector((state) => state?.authReducer);
  const { setLoading } = useLoader();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [memberList, setMemberList] = useState([]);

  useEffect(() => {
    getAllMemberData();
  }, []);

  const getAllMemberData = async () => {
    setLoading(true);
    const result = await api?.GetAllUserListing({
      pageNumber: 1,
      pageSize: 100,
      totalRowsCount: 0,
      sortField: "",
      sortDirection: "",
      search: "",
      filter: {
        propertyTypeId: 0,
        roleId: 0,
        communityId,
      },
    });
    if (result?.data?.headers?.code === 200) {
      setMemberList(result?.data?.body?.data);
    } else {
      toast.error(result?.data?.headers?.message);
    }
    setLoading(false);
  };

  return { memberList };
};

export default useMember;
