import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import MyInput from "../../../../../components/MyInput";
import MyButton from "../../../../../components/MyButton";
import styles from "./Step1.module.css";
import MyCheckBox from "../../../../../components/MyCheckBox";
import MyPhoneCode from "../../../../../components/MyPhoneCode";
import { FaLock } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";

const Step1 = ({ signup, buttonHanler }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  return (
    <>
      <div className={styles.header}>
        <h1>Create an account</h1>
        <h2>
          Enter your account details below or{" "}
          <span onClick={buttonHanler}>login</span>
        </h2>
      </div>
      <form
        onSubmit={signup.form.handleSubmit}
        className={styles.formComponent}
        noValidate
        autoComplete="off"
      >
        <div className={styles?.groupsWrapper}>
          <div>
            <MyInput
              name="name"
              label="Name"
              value={signup.form.values.name}
              setter={signup?.form.handleChange("name")}
              error={signup.form.errors.name}
            />
          </div>
          <div className={styles.inputWrapper}>
            <div className={styles.codeBox}>
              <MyPhoneCode
                name="code"
                label="C-Code"
                value={signup.form.values.code}
                setter={signup?.form.handleChange("code")}
                error={signup.form.errors.code}
              />
              {/* <MyInput
                name="code"
                label="C-Code"
                value={signup.form.values.code}
                setter={signup?.form.handleChange("code")}
                error={signup.form.errors.code}
              /> */}
            </div>
            <div className={styles.phoneBox}>
              <MyInput
                name="phoneNumber"
                label="Number"
                value={signup.form.values.phoneNumber}
                setter={signup?.form.handleChange("phoneNumber")}
                error={signup.form.errors.phoneNumber}
              />
            </div>
          </div>
          <div>
            <MyInput
              name="email"
              label="Email Address"
              value={signup.form.values.email}
              setter={signup?.form.handleChange("email")}
              error={signup.form.errors.email}
            />
          </div>

          <div>
            <MyInput
              name="password"
              label="Password"
              value={signup.form.values.password}
              setter={signup?.form.handleChange("password")}
              error={signup.form.errors.password}
              type={showPassword ? "text" : "password"}
              leftIcon={
                showPassword ? (
                  <FaLockOpen
                    color="var(--main-color-dark)"
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <FaLock
                    color="var(--main-color-dark)"
                    style={{ cursor: "pointer" }}
                  />
                )
              }
              leftIconHandler={() => setShowPassword(!showPassword)}
            />
          </div>

          <div>
            <MyInput
              name="confirmPassword"
              label="Confirm Password"
              value={signup.form.values.confirmPassword}
              setter={signup?.form.handleChange("confirmPassword")}
              error={signup.form.errors.confirmPassword}
              type={showConfirmPassword ? "text" : "password"}
              leftIcon={
                showConfirmPassword ? (
                  <FaLockOpen
                    color="var(--main-color-dark)"
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <FaLock
                    color="var(--main-color-dark)"
                    style={{ cursor: "pointer" }}
                  />
                )
              }
              leftIconHandler={() =>
                setShowConfirmPassword(!showConfirmPassword)
              }
            />
          </div>
          <div className={styles.terms}>
            <MyCheckBox
              name="termsNcond"
              value={signup.form.values.termsNcond}
              setter={signup?.form.handleChange("termsNcond")}
              error={signup.form.errors.termsNcond}
            />
            <h2 className={styles?.termsNCondition}>
              I have read and accept <span>Terms and Conditions</span> and{" "}
              <span>Privacy Policy</span>
            </h2>
          </div>
        </div>
        <div className={styles.btnWrapper}>
          <MyButton
            btnText="Sign Up"
            type="submit"
            className={[styles.extraBtnStyle, "signup-submit"].join(" ")}
          />
        </div>
      </form>
    </>
  );
};

export default Step1;
