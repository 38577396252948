import React from "react";
import classes from "./FilterTabs.module.css";
import FilterButton from "../FilterButton";

const FilterTabs = ({ tabs, handleFilterChange, activeFilters }) => {
  return (
    <div className={classes["tabs-section"]}>
      {tabs?.map((item, index) => (
        <FilterButton
          text={item?.name}
          active={activeFilters[item?.slug]}
          onClick={() => handleFilterChange(item?.slug)}
        />
      ))}
    </div>
  );
};

export default FilterTabs;
