import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useApi from "./useApi";
import useLoader from "./useLoader";
const useService = () => {
  const { setLoading } = useLoader();
  const serviceApi = useApi();
  const [services, setServices] = useState([]);
  const [selectedData, setSelectedData] = useState(undefined);
  const getAllCommunityServiceListing = async (
    status = "",
    isActive = "",
    pageNumber = 1,
    pageSize = 10,
    totalCount
  ) => {
    setLoading(true);
    const result = await serviceApi?.getAllCommunityServiceListing({
      pageNumber,
      pageSize,
      totalRowsCount: 0,
      sortField: "",
      sortDirection: "",
      search: "",
      filter: {
        status,
        isActive,
      },
    });
    if (result?.data?.headers?.code == 200) {
      setServices(result?.data?.body?.data);
    } else {
      toast.error(result?.data?.headers?.msg);
    }
    setLoading(false);
  };
  const deleteCommunityService = async (id) => {
    setLoading(true);
    const response = await serviceApi.deleteCommunityService(id);
    if (response?.data?.headers?.code == 200) {
      getAllCommunityServiceListing();
      toast.success("Service Deleted Successfully");
    } else {
      toast.error(response?.data?.headers?.msg);
    }
    setLoading(false);
  };

  const deleteCommunityServiceCategory = async (id) => {
    setLoading(true);
    const response = await serviceApi.deleteCommunityServiceCategory(id);
    if (response?.data?.headers?.code == 200) {
      getAllCommunityServiceListing();
      toast.success("Service Deleted Successfully");
    } else {
      toast.error(response?.data?.headers?.msg);
    }
    setLoading(false);
  };
  const getCommunityServiceById = async (id) => {
    setLoading(true);
    const response = await serviceApi?.getCommunityServiceById(id);
    if (response?.data?.headers?.code == 200) {
      setSelectedData(response?.data?.body);
    } else {
      toast.error(response?.data?.headers?.code?.msg);
    }
    setLoading(false);
  };

  const addOrEditCommunityService = async (params) => {
    setLoading(true);
    const response = await serviceApi?.addOrEditCommunityService(
      selectedData,
      params
    );
    if (response?.data?.headers?.code == 200) {
      if (selectedData) {
        toast.success("Community Service Updated Successfully");
      } else toast.success("Community Service Created Successfully");
      getAllCommunityServiceListing();
    } else {
      toast.error(response?.data?.headers?.msg);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllCommunityServiceListing();
  }, []);
  return {
    services,
    selectedData,
    setSelectedData,
    getCommunityServiceById,
    getAllCommunityServiceListing,
    deleteCommunityService,
    addOrEditCommunityService,
    deleteCommunityServiceCategory,
  };
};

export default useService;
