import React, { forwardRef, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./MenuItem.module.css";

const MenuItem = forwardRef(
  (
    {
      text,
      icon,
      path,
      listOfOptions = [],
      className,
      active,
      setMarkAsActive,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation()?.pathname.split("/")[1];
    const menuItem = useRef(null);
    const itemRef = useRef(null);
    const handleActive = () => {
      if (listOfOptions === null || listOfOptions?.length <= 0) {
        navigate(path);
        setMarkAsActive();
      }
    };

    return (
      <>
        <li ref={menuItem} className={[className].join(" ")}>
          <div
            ref={itemRef}
            className={[
              classes["menuItem"],
              classes["overflow-x-hidden"],
              (text === "Dashboard" ? classes.activeMenu : location) &&
                (active || location === path) &&
                classes.activeMenu,
            ].join(" ")}
            onClick={handleActive}
          >
            <div
              className={[
                classes["menuContent"],
                classes["overflow-x-hidden"],
                (text === "Dashboard" ? classes.activeMenu : location) &&
                  (active || location === path) &&
                  classes.activeMenu,
              ].join(" ")}
            >
              {icon ? (
                <div className={classes["menuIcon-wrapper"]}>{icon}</div>
              ) : (
                <span className={classes["circle-indicator"]} />
              )}
              {text && <p className={classes["menu-name"]}>{text}</p>}
            </div>
          </div>
        </li>
      </>
    );
  }
);
export default MenuItem;
