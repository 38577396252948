import React, { useState } from "react";
import classes from "./Complains.module.css";
import AnnouncementCard from "../../../../components/AnnouncementCard";
import FilterButton from "../../../../components/FilterButton";
import AddButton from "../../../../components/AddButton";
import DrawerWrapper from "../../../../components/DrawerWrapper";
import AddAnouncement from "./AddComplain";
import PreviewAnouncement from "./PreviewComplain";
import ComplainCard from "../../../../components/ComplainCard";
import AddComplain from "./AddComplain";
import PreviewComplain from "./PreviewComplain";
import Header from "../../../../components/Header";
import PaddingContainer from "../../../../components/PaddingContainer";

const Complains = () => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const [form, setForm] = useState({
    active: false,
    date: "",
    userAddress: "",
    username: "",
    complainTitle: "",
    complainDescription: "",
  });

  const complainData = [
    {
      active: false,
      complainNo: "005525",
      date: "12 Jan 2024",
      userAddress: "Flat/301 - Block A - 1st Floor",
      username: "Haris Ali",
      complainTitle: "Water Leakage",
      complainDescription:
        "Dear Unionis simply dummy text of the printing and typesetting industry...",
    },
    {
      active: true,
      complainNo: "005525",
      date: "12 Jan 2024",
      userAddress: "Flat/302 - Block A - 1st Floor",
      username: "Saad Ashraf",
      complainTitle: "Water Leakage",
      complainDescription:
        "Dear Unionis simply dummy text of the printing and typesetting industry...",
    },
  ];

  const handlePreview = (data) => {
    setPreviewData(data);
    setOpenPreviewModal(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpenAddModal(false);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleOpenAdd = () => {
    setOpenAddModal(true);
    setForm({
      active: false,
      date: "",
      userAddress: "",
      username: "",
      complainTitle: "",
      complainDescription: "",
    });
  };

  const handleDelete = () => {
    console.log("Announcement Deleted");
  };

  const handleAccept = (id) => {
    console.log("Accept Called", id);
  };

  const handleReject = (id) => {
    console.log("Reject Called", id);
  };

  return (
    <>
      <Header title={"Complain Center"} />
      <div className={classes["headerWrapper"]}>
        <div className={classes["tabs-section"]}>
          <FilterButton text={"Pending"} active={true} />
          <FilterButton text={"In Progress"} />
          <FilterButton text={"Close"} active={true} />
        </div>
        <AddButton handler={handleOpenAdd} />
      </div>
      <div className={classes["subSection"]}>
        {/* <h1 className={classes["sub-heading"]}>Complain Center</h1> */}
        {complainData?.map((item, index) => (
          <ComplainCard
            data={item}
            isSetting={true}
            handler={handlePreview}
            handleAccept={handleAccept}
            handleReject={handleReject}
          />
        ))}
      </div>
      <DrawerWrapper
        headingText={"Create New Complain"}
        open={openAddModal}
        setOpen={setOpenAddModal}
        btnText={"Save and Send"}
        extraBtn={"Draft"}
        handleSubmit={handleSubmit}
      >
        <AddComplain
          form={form}
          handleChange={handleChange}
          handleDelete={handleDelete}
        />
      </DrawerWrapper>
      <DrawerWrapper
        headingText={"Complain Preview"}
        open={openPreviewModal}
        setOpen={setOpenPreviewModal}
        btnText={""}
        extraBtn={""}
        // handleSubmit={handleSubmit}
      >
        <PreviewComplain
          data={previewData}
          handleDelete={handleDelete}
          handleAccept={handleAccept}
          handleReject={handleReject}
        />
      </DrawerWrapper>
    </>
  );
};

export default Complains;
