import React from "react";
import styles from "./AuthSubComponent.module.css";
import { Button, Grid } from "@mui/material";
import CommonCard from "../CommonCard";
import { authImage, badge } from "../../assets/constant";
import MyButton from "../MyButton";

const AuthSubComponent = ({ text, btnText, btnFunc }) => {
  return (
    <Grid lg={6} md={5} sm={0} xs={0} className={styles.wrapper}>
      <CommonCard className={styles.sideWrapper}>
        <h1>{text}</h1>
        <div className={styles.imageWrapper}>
          <img src={authImage} alt="auth-image" />
        </div>
        <div className={styles.badgeWrapper}>
          <img src={badge} alt="badge-image" />
        </div>
        <div style={{ textAlign: "center" }}>
          <MyButton
            btnText={btnText}
            className={styles.btn}
            type="button"
            handler={btnFunc}
          />
        </div>
      </CommonCard>
    </Grid>
  );
};

export default AuthSubComponent;
