import React from "react";
import classes from "./AmenitiesSettingsCard.module.css";
import { BsChevronRight } from "react-icons/bs";

const AmenitiesSettingsCard = ({ name, count, handler }) => {
  return (
    <div className={classes["cardWrapper"]} onClick={handler}>
      <p>{name}</p>
      <div className={classes["countWrapper"]}>
        <p>{count} Records</p>
        <BsChevronRight
          size={20}
          style={{
            strokeWidth: "2px",
            color: "var(--main-color)",
          }}
        />
      </div>
    </div>
  );
};

export default AmenitiesSettingsCard;
