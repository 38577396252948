import React, { useState } from "react";
import useAccounts from "../../../Hooks/useAccounts";
import PaddingContainer from "../../../components/PaddingContainer";
import Header from "../../../components/Header";
import classes from "./Accounts.module.css";
import FilterButton from "../../../components/FilterButton";
import { HiPlusSm } from "react-icons/hi";
import AddButton from "../../../components/AddButton";
import AddFinancialYearModal from "../../../modals/AddFinancialYearModal";
import DashboardCard from "../../../components/DashboardCard";
import ExpenseCard from "../../../components/ExpenseCard";
import ExpenseDetailCard from "../../../components/ExpenseDetailCard";
import HeaderAction from "../../../components/HeaderAction";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Accounts = () => {
  const account = useAccounts();
  const navigate = useNavigate();
  const defaultDate = new Date();
  const [show, setShow] = useState(false);
  const [openDetailView, setOpenDetailView] = useState(false);
  const [detailedData, setDetailedData] = useState({});
  const [addNewExpense, setAddNewExpense] = useState({
    expenseName: "",
    amount: 0,
  });
  const [form, setForm] = useState({
    to: defaultDate,
    from: defaultDate,
  });

  const financialYears = [
    {
      id: 1,
      to: "Dec 2022",
      from: "Dec 2023",
    },
    {
      id: 2,
      to: "Dec 2023",
      from: "Dec 2024",
    },
    {
      id: 2,
      to: "Dec 2023",
      from: "Dec 2024",
    },
    {
      id: 2,
      to: "Dec 2023",
      from: "Dec 2024",
    },
    {
      id: 2,
      to: "Dec 2023",
      from: "Dec 2024",
    },
  ];

  const accountAmountList = [
    {
      id: 1,
      name: "Expense",
      expenseList: [
        {
          id: 11,
          expenseName: "Lift",
          amount: 0,
        },
        {
          id: 12,
          expenseName: "Guard Salary",
          amount: 0,
        },
        {
          id: 13,
          expenseName: "Building Maintenance",
          amount: 0,
        },
        {
          id: 14,
          expenseName: "Internet Bill",
          amount: 0,
        },
      ],
    },
    {
      id: 2,
      name: "Income",
      expenseList: [
        {
          id: 21,
          expenseName: "Lift",
          amount: 0,
        },
        {
          id: 22,
          expenseName: "Guard Salary",
          amount: 0,
        },
        {
          id: 23,
          expenseName: "Building Maintenance",
          amount: 0,
        },
        {
          id: 24,
          expenseName: "Internet Bill",
          amount: 0,
        },
      ],
    },
  ];

  const createFinancialYearHandler = () => {
    console.log(form);
  };

  const handleDetailView = (item) => {
    setDetailedData(item);
    setOpenDetailView(true);
  };

  const handleSingleExpense = (e, id) => {
    e.stopPropagation();
    navigate(`/setupaccounts/${id}`);
  };

  const handleExpenseAddFormChange = (e) => {
    const { name, value } = e.target;
    setAddNewExpense((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddNew = () => {
    setDetailedData((prev) => ({
      ...prev,
      expenseList: [...prev?.expenseList, addNewExpense],
    }));
    setAddNewExpense({ expenseName: "", amount: 0 });
  };

  return (
    <>
      <>
        {!openDetailView ? (
          <>
            <Header title={"Accounts"} />
            <div className={classes["topWrapper"]}>
              <div className={classes["tabs-section"]}>
                {financialYears?.map((item, index) => (
                  <FilterButton
                    text={`${item?.to} - ${item?.from}`}
                    active={item?.id === 1}
                  />
                ))}
              </div>
              <span></span>
              <AddButton handler={() => setShow(true)} />
            </div>
            <div className={classes["orangeCardsWrapper"]}>
              <DashboardCard count={"1000"} text={"Cash Received"} />
              <DashboardCard count={"1000"} text={"Total Dues"} />
              <DashboardCard count={"1000"} text={"Total Expense"} />
              <DashboardCard count={"1000"} text={"Approvals"} />
            </div>
            <div className={classes["expenseCardsSection"]}>
              {accountAmountList?.map((item, index) => (
                <ExpenseCard
                  key={index}
                  id={item?.id}
                  title={item?.name}
                  expenseList={item?.expenseList}
                  detailCardHandler={() => handleDetailView(item)}
                  singleExpenseHandler={handleSingleExpense}
                />
              ))}
            </div>
          </>
        ) : (
          <>
            <HeaderAction
              title={"Accounts"}
              onClick={() => setOpenDetailView(false)}
            />
            <ExpenseDetailCard
              data={detailedData}
              form={addNewExpense}
              singleExpenseHandler={handleSingleExpense}
              handleAddNew={handleAddNew}
              handleChange={handleExpenseAddFormChange}
            />
          </>
        )}
      </>
      <AddFinancialYearModal
        form={form}
        setForm={setForm}
        show={show}
        setShow={setShow}
        handler={createFinancialYearHandler}
      />
    </>
  );
};

export default Accounts;
