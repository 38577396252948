import React from "react";
import Header from "../../../components/Header";
import PaddingContainer from "../../../components/PaddingContainer";
import DefaultDashboardActions from "./DefaultDashboardActions";

const DefaultDashboard = () => {
  return (
    <PaddingContainer>
      <Header title={"Dashboard"} />
      <DefaultDashboardActions />
    </PaddingContainer>
  );
};

export default DefaultDashboard;
