import React from "react";
import classes from "./AddButton.module.css";
import { FaPlus } from "react-icons/fa";

const AddButton = ({ handler }) => {
  return (
    <div className={[classes.addMenuBtn, "c-p"].join(" ")} onClick={handler}>
      <FaPlus color="var(--secondary-color)" size={25} />
    </div>
  );
};

export default AddButton;
