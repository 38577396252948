import React, { useState } from "react";
import classes from "./MainDashboard.module.css";
import { HiOutlineViewGridAdd } from "react-icons/hi";
import { BsChevronDown } from "react-icons/bs";
import MySelect from "../../../../components/MySelect";
import DashboardCard from "../../../../components/DashboardCard";
import ComplainCard from "../../../../components/ComplainCard";
import AnnouncementCard from "../../../../components/AnnouncementCard";
import RecommendationsCard from "../../../../components/RecommendationsCard";
import { useNavigate } from "react-router-dom";

import PaddingContainer from "../../../../components/PaddingContainer";
import AmenitiesDashboard from "../AmenitiesDashboard";

const MainDashboard = ({ complainData, announcementData, filter }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className={classes["filterComponent"]}>
        <p>This {filter?.label}</p>
        <BsChevronDown
          color={"var(--main-color)"}
          size={15}
          style={{ strokeWidth: "2px" }}
        />
      </div>
      <div
        className={[classes["orangeCardsWrapper"], "tabs-section"].join(" ")}
      >
        <DashboardCard count={"1000"} text={"Cash Received"} />
        <DashboardCard count={"1000"} text={"Total Dues"} />
        <DashboardCard count={"1000"} text={"Total Expense"} />
        <DashboardCard count={"1000"} text={"Approvals"} />
      </div>
      <div className={classes["subSection"]}>
        <h1 className={classes["sub-heading"]}>Complain Center</h1>
        <ComplainCard data={complainData} />
      </div>
      <div className={classes["subSection"]}>
        <h1 className={classes["sub-heading"]}>Announcements</h1>
        <AnnouncementCard data={announcementData} />
      </div>
      <div className={classes["subSection"]}>
        <h1 className={classes["sub-heading"]}>Recommendations</h1>
        <RecommendationsCard data={announcementData} />
      </div>
    </>
  );
};

export default MainDashboard;
