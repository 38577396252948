import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa6";
import MenuItem from "../../../components/MenuItem";
import classes from "./LeftAside.module.css";

const LeftAside = ({
  sidebarAside,
  isSidebarClose,
  setIsSidebarClose,
  active,
  menuList,
  styles,
}) => {
  const [markAsActive, setMarkAsActive] = useState(0);
  const [selectedMenu, setSelectedMenu] = useState(menuList[0]);
  const [isForward, setIsForward] = useState(true);

  useEffect(() => {
    if (selectedMenu?.subModules.length) {
      setMarkAsActive(null);
    }
  }, [selectedMenu]);

  const handleMenuSelect = (index, item) => {
    if (item?.subModules?.length) {
      setIsForward(!selectedMenu);
      setSelectedMenu(item);
      setMarkAsActive(index);
    } else {
      setMarkAsActive(index);
    }
  };

  return (
    <aside
      className={[
        classes["sidebar-side-transition"],
        classes["sidebar__sideContent"],
        isSidebarClose && styles["width-0"],
      ].join(" ")}
      ref={sidebarAside}
    >
      <div
        className={[
          classes["sidebar__mainMenu"],
          classes["overflow-x-hidden"],
        ].join(" ")}
      >
        <div
          className={[
            classes["sidebar__header"],
            classes["sidebarList"],
            classes["overflow-x-hidden"],
          ].join(" ")}
          style={{
            marginLeft: "10px",
            display: "flex",
            gap: "5px",
            alignItems: "center",
          }}
        >
          {selectedMenu?.subModules.length ? (
            <FaArrowLeft
              color={"var(--black-color)"}
              size={20}
              onClick={() => {
                setIsForward(true);
                setSelectedMenu(menuList[0]);
              }}
              style={{ margin: "20px 0px" }}
              className="c-p"
            />
          ) : (
            ""
          )}
          <div className={classes["menuHeader"]}>
            <h4>
              {selectedMenu?.subModules.length ? selectedMenu?.name : "Explore"}
            </h4>
            <span onClick={setIsSidebarClose}>x</span>
            {/* <ImCancelCircle color="var(--main-color-dark)" size={20} /> */}
          </div>
        </div>

        {!selectedMenu?.subModules.length ? (
          <ul
            className={[
              classes["sidebarList"],
              isForward ? classes["slideIn"] : classes["slideOut"],
            ].join(" ")}
          >
            {menuList?.map((item, index) => (
              <MenuItem
                path={!item?.subModules.length && item?.router}
                text={item?.name}
                icon={<i className={item?.iconCssClass}></i>}
                setMarkAsActive={() => {
                  handleMenuSelect(index, item);
                }}
                markAsActive={markAsActive === index}
                key={index}
              />
            ))}
          </ul>
        ) : (
          <ul
            className={[
              classes["sidebarList"],
              !isForward ? classes["slideInLeft"] : classes["slideOutRight"],
            ].join(" ")}
          >
            {selectedMenu?.subModules?.map((item, index) => (
              <MenuItem
                key={index}
                path={item?.router}
                text={item?.name}
                icon={<i className={`${item?.iconCssClass}`} />}
                setMarkAsActive={() => {
                  handleMenuSelect(index, item);
                }}
                active={markAsActive === index}
              />
            ))}
          </ul>
        )}
      </div>
    </aside>
  );
};

export default LeftAside;
