import * as React from "react";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers";

export default function DateField({
  label = "",
  value,
  setter,
  datetimePicker = false,
}) {
  const parsedValue = value ? dayjs(value) : null;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div>
        {!datetimePicker ? (
          <DatePicker
            label={label}
            value={parsedValue}
            onChange={(newValue) =>
              setter(newValue ? newValue.format("YYYY-MM-DD") : null)
            }
          />
        ) : (
          <DateTimePicker
            label={label}
            value={parsedValue}
            onChange={(newValue) =>
              setter(newValue ? newValue.format("YYYY-MM-DDTHH:mm:ss") : null)
            }
          />
        )}
      </div>
    </LocalizationProvider>
  );
}
