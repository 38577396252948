import moment from "moment";
export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const CreateFormData = (data) => {
  const formData = new FormData();
  for (let key in data) {
    formData.append(key, data[key]);
  }
  return formData;
};

export const formRegEx = /([a-z])([A-Z])/g;
export const formRegExReplacer = "$1 $2";

export var recordsLimit = 10;

export const dateDifference = (date) => {
  let duration = moment.duration(moment().diff(date));
  let daysDiff = duration.asDays().toFixed(0);
  return +daysDiff;
};

export const dateFromToFormatter = (fromDateTime, toDateTime) => {
  return moment(fromDateTime).format("Do MMM, YYYY") ===
    moment(toDateTime).format("Do MMM, YYYY")
    ? moment(fromDateTime).format("Do MMM, YYYY")
    : `${moment(fromDateTime).format("Do MMM, YYYY")} to ${moment(
        toDateTime
      ).format("Do MMM, YYYY")}`;
};

export const timeFromToFormatter = (fromDateTime, toDateTime) => {
  return moment(fromDateTime).format("hh:mm A") ===
    moment(toDateTime).format("hh:mm A")
    ? moment(fromDateTime).format("hh:mm A")
    : `${moment(fromDateTime).format("hh:mm A")} to ${moment(toDateTime).format(
        "hh:mm A"
      )}`;
};

export const capitalizeFirstLetter = (l) =>
  l.charAt(0).toUpperCase() + l.slice(1);

export const hidePhoneNumber = (phoneNumber) => {
  const numDigitsToHide = phoneNumber?.length - 5;

  const hiddenPart = "*".repeat(numDigitsToHide);
  const firstPart = phoneNumber.slice(0, 1);
  const lastPart = phoneNumber.slice(-4);

  const hiddenPhoneNumber = `${firstPart}${hiddenPart}${lastPart}`;

  return hiddenPhoneNumber;
};

export const fileDownloadFunction = (name, data, type) => {
  const blob = new Blob([data], { type });
  const fileUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = fileUrl;
  link.setAttribute("download", name);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function dataURLtoFile(data, type) {
  const binaryData = atob(data);
  const byteNumbers = new Uint8Array(binaryData.length);
  for (let i = 0; i < binaryData.length; i++) {
    byteNumbers[i] = binaryData.charCodeAt(i);
  }
  return byteNumbers;
}

export function amenityNameFormatter(ammenityName, prefix, unitName) {
  if (prefix !== undefined && unitName !== undefined) {
    const displayName = prefix ? ` ${prefix}-${unitName}` : ` - ${unitName}`;
    return ammenityName + displayName;
  } else {
    return "";
  }
}

export const filteredDataHandler = (data) => {
  var filtered = {};
  for (var i = 0; i < data?.length; i++) {
    if (data[i].ammenityName in filtered) {
      filtered = {
        ...filtered,
        [data[i].ammenityName]: [...filtered[data[i].ammenityName], data[i]],
      };
    } else {
      filtered = { ...filtered, [data[i].ammenityName]: [data[i]] };
    }
  }
  const result = Object.entries(filtered);
  return result;
};

export const filterCompleteCommunityList = (data) => {
  const result = data?.find((item) => item.isSetupCompleted === true);
  if (Object.keys(result).length) {
    return result;
  } else {
    return data?.find((item) => item?.communityName === "Quantum Paradigm");
  }
};

export const filterIncompleteCommunityList = (data) => {
  return data?.find(
    (item) =>
      item.isSetupCompleted === false &&
      item?.communityName !== "Quantum Paradigm"
  );
};

export const handleMaskedCNICInputChange = (form, name, e) => {
  let value = e.target.value.replace(/\D/g, "");
  if (value.length > 5) value = `${value.slice(0, 5)}-${value.slice(5)}`;
  if (value.length > 13) value = `${value.slice(0, 13)}-${value.slice(13, 14)}`;
  form.setFieldValue(name, value);
};
