import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import useApi from "./useApi";
import useLoader from "./useLoader";

const useApprovals = () => {
  const api = useApi();
  const { communityId } = useSelector((state) => state?.authReducer);
  const { setLoading } = useLoader();
  const navigate = useNavigate();
  const phoneRegExp = /^(\(\+\d{1,3}\))?\s?\d{10}$/;
  const cnicRegExp = /^\d{5}-\d{7}-\d{1}$/;
  const [approvalActionStatus, setApprovalActionStatus] = useState(0);
  const [approvalStatus, setApprovalStatus] = useState(0);
  const [approvalsList, setApprovalsList] = useState([]);
  const [approvalStatusList, setApprovalStatusList] = useState([]);
  const [openPreview, setOpenPreview] = useState(false);
  const [singleApproval, setSingleApproval] = useState({
    approvalRequestId: 0,
    name: "",
    cellNo: "",
    countryCode: "",
    address: "",
    cnic: "",
    emergencyContact: 0,
    emergencyPersonName: "",
    propertyTypeId: null,
    remarks: "",
    requestedDate: "",
    communitySeriesDetailId: null,
    role: null,
    designationId: null,
    isUnionMember: false,
  });

  useEffect(() => {
    getAllApprovals();
  }, [approvalStatus]);

  useEffect(() => {
    getAllApprovalsStatus();
  }, []);

  const approvalPreviewForm = useFormik({
    initialValues: singleApproval,
    enableReinitialize: true,
    isInitialValid: false,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Required"),
      cellNo: Yup.string()
        .required("Required")
        .matches(phoneRegExp, "Phone number is not valid"),
      countryCode: Yup.string().required("Required"),
      propertyTypeId: Yup.object().required("Required"),
      role: Yup.object().required("Required"),
      communitySeriesDetailId: Yup.object().required("Required"),
      designationId: Yup.object().required("Required"),
      //   emergencyContact: Yup.number(),
      //   emergencyPersonName: Yup.string(),
      //   cnic: Yup.string(),
      //   address: Yup.string(),
    }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const data = {
        communityId,
        propertyTypeId: values?.propertyTypeId?.value,
        communitySeriesDetailId: values?.communitySeriesDetailId?.value,
        approvalRequestId: singleApproval?.approvalRequestId,
        approvalStatusId: approvalActionStatus,
        name: values.name,
        roleId: values?.role?.value,
        designationId: values?.designationId?.value,
        isUnionMember: values?.isUnionMember,
      };
      api
        .handleApprovalRequest({
          data,
        })
        .then((response) => {
          if (response?.data?.headers?.code === 200) {
            toast.success(response?.data?.headers?.msg);
            setOpenPreview(false);
          } else {
            toast.error(response?.data?.headers?.msg);
          }
        })
        .finally(() => {
          setLoading(false);
        });
      // window.location.reload();
    },
  });

  const getAllApprovals = async () => {
    // debugger;
    setLoading(true);
    const result = await api?.GetAllApprovalsListing({
      pageNumber: 1,
      pageSize: 10,
      totalRowsCount: 0,
      sortField: "",
      sortDirection: "",
      search: "",
      filter: {
        approvalStatusId: approvalStatus,
        communityId,
      },
    });
    if (result?.data?.headers?.code === 200) {
      setApprovalsList(result?.data?.body?.data);
    } else {
      toast.error(result?.data?.headers?.message);
    }
    setLoading(false);
  };

  async function getAllApprovalsStatus() {
    setLoading(true);
    const result = await api?.GetAllApprovalStatusListing();
    if (result?.data?.headers?.code === 200) {
      setApprovalStatusList(result?.data?.body);
    } else {
      toast.error(result?.data?.headers?.message);
    }
    setLoading(false);
  }

  async function getApprovalById(id) {
    setLoading(true);
    await api
      ?.GetApprovalById(id)
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          const item = response?.data?.body[0];
          setSingleApproval({
            ...item,
            approvalRequestId: id,
            propertyTypeId: { value: item?.propertyTypeId },
            cellNo: item?.cellNo?.slice(3, 13),
            countryCode: item?.cellNo?.slice(0, 3),
            communitySeriesDetailId: {
              value: item?.communitySeriesDetailId,
              label: `${item?.ammenityName} ${item?.prefix}-${item?.unitName}`,
            },
          });
        } else {
          toast.error(response?.data?.headers?.message);
          return;
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return {
    getApprovalById,
    setApprovalStatus,
    setApprovalActionStatus,
    approvalStatus,
    approvalsList,
    singleApproval,
    approvalPreviewForm,
    approvalStatusList,
    openPreview,
    setOpenPreview,
  };
};

export default useApprovals;
