import React from "react";
import { Box, Grid } from "@mui/material";
import MySelect from "../../../../../components/MySelect";
import classes from "./PreviewComplain.module.css";
import MyInput from "../../../../../components/MyInput";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DeleteButton from "../../../../../components/DeleteButton";
import MyCheckBox from "../../../../../components/MyCheckBox";
import EditButton from "../../../../../components/EditButton";
import ComplainCard from "../../../../../components/ComplainCard";

const PreviewComplain = ({
  data,
  handleDelete,
  handleAccept,
  handleReject,
}) => {
  return (
    <>
      <div className={classes["headerActions"]}>
        <MyCheckBox
          name={"active"}
          value={data?.active}
          label={data?.active ? "Active" : "InActive"}
        />
        <div className={classes["actionsWrapper"]}>
          <DeleteButton handler={handleDelete} />
        </div>
      </div>
      {data && (
        <ComplainCard
          data={data}
          isSetting={true}
          handleAccept={handleAccept}
          handleReject={handleReject}
        />
      )}
    </>
  );
};

export default PreviewComplain;
