import React from "react";
import classes from "./OverviewItem.module.css";

const OverviewItem = ({ name, description, time }) => {
  return (
    <div className={classes["mainWrapper"]}>
      <h1>
        <span>@{name}</span> {description}
      </h1>
      <p>{time} ago</p>
    </div>
  );
};

export default OverviewItem;
