import React from "react";
import { Modal } from "@mui/material";
import classes from "./AllotParkingModal.module.css";
import MyButton from "../../components/MyButton";

const AllotParkingModal = ({ name, show, setShow, children, handler }) => {
  return (
    <Modal
      open={show}
      onClose={() => setShow(false)} // Ensure this is a function that sets `show` to false
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={classes["container"]}>
        <div className={classes["heading-wrapper"]}>
          <h1>Allot {name}</h1>
        </div>
        {children}
      </div>
    </Modal>
  );
};

export default AllotParkingModal;
