import React from "react";
import { Box, Grid } from "@mui/material";
import MySelect from "../../../../../components/MySelect";
import classes from "./AddComplain.module.css";
import MyInput from "../../../../../components/MyInput";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DeleteButton from "../../../../../components/DeleteButton";
import MyCheckBox from "../../../../../components/MyCheckBox";

const AddComplain = ({ form, handleChange, handleDelete }) => {
  return (
    <>
      <Box
        sx={{
          "& .MuiTextField-root": { my: 1, ml: 0, width: "100%" },
        }}
        className={classes["formWrapper"]}
        noValidate
        autoComplete="off"
      >
        <div className={classes["headerActions"]}>
          <MyCheckBox
            name={"active"}
            value={form?.active}
            setter={handleChange}
            label={form?.active ? "Active" : "InActive"}
          />
          <DeleteButton handler={handleDelete} />
        </div>
        {/* <DatePicker
          name="date"
          label="Announcement Date"
          //   value={form?.date}
          setter={handleChange}
        /> */}
        <MyInput
          name="username"
          label="Username"
          value={form?.username}
          setter={handleChange}
        />
        <MyInput
          name="userAddress"
          label="User Address"
          value={form?.userAddress}
          setter={handleChange}
        />
        <MyInput
          name="complainTitle"
          label="Complain title"
          value={form?.complainTitle}
          setter={handleChange}
        />
        <MyInput
          name="complainDescription"
          label="Complain description"
          value={form?.complainDescription}
          setter={handleChange}
        />
      </Box>
    </>
  );
};

export default AddComplain;
