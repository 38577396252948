import React, { useState } from "react";
import AddButton from "../../../../components/AddButton";
import AnnouncementCard from "../../../../components/AnnouncementCard";
import FilterButton from "../../../../components/FilterButton";
import HeaderAction from "../../../../components/HeaderAction";
import useAnnouncement from "../../../../Hooks/useAnnouncements";
import AddOrEditAnnouncement from "./AddOrEditAnouncement";
import classes from "./Announcements.module.css";
import PreviewAnouncement from "./PreviewAnouncement";
import Loader from "../../../../components/Loader";
import useLoader from "../../../../Hooks/useLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import Header from "../../../../components/Header";
import PaddingContainer from "../../../../components/PaddingContainer";
const allListingType = ["Current", "Recent", "Deleted", "Drafts", "Active"];
const Announcements = () => {
  const {
    selectedData,
    setSelectedData,
    announcements,
    getAllAnnouncementListing,
    deleteAnnouncement,
    getAnnouncementById,
    addOrEditAnnouncement,
    visible,
    totalCount,
  } = useAnnouncement();
  //useAnnouncement apis contains all api function
  const [selectedListingStatus, setSelectedListingStatus] = useState("");
  const [viewAnnouncement, setViewAnnouncement] = useState(false);
  const { loading } = useLoader();

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <>
            {!viewAnnouncement && selectedData === undefined && (
              <>
                <Header title={"Announcements"} />
                <div className={classes["headerWrapper"]}>
                  <div className={classes["tabs-section"]}>
                    {allListingType?.map((listingType) => (
                      <FilterButton
                        text={listingType.toUpperCase()}
                        active={listingType === selectedListingStatus}
                        onClick={() => {
                          setSelectedListingStatus(listingType);
                          getAllAnnouncementListing(listingType);
                        }}
                      />
                    ))}
                  </div>
                  <AddButton
                    handler={() => {
                      setSelectedData(null);
                    }}
                  />
                </div>

                <div className={classes["subSection"]}>
                  {/* <h1 className={classes["sub-heading"]}>Announcements</h1> */}

                  {announcements?.map((item, index) => (
                    <AnnouncementCard
                      key={index}
                      onAnnouncementView={() => {
                        setViewAnnouncement(true);
                      }}
                      onGetAnnouncementById={() => {
                        getAnnouncementById(item?.announcementId);
                      }}
                      onDeleteAnnouncement={() => {
                        deleteAnnouncement(item?.announcementId);
                      }}
                      data={item}
                    />
                  ))}
                </div>
              </>
            )}
          </>

          {!viewAnnouncement &&
            (selectedData === null || selectedData instanceof Object) && (
              <div>
                <AddOrEditAnnouncement
                  selectedData={selectedData}
                  onPrevious={() => setSelectedData(undefined)}
                  onFormSubmit={addOrEditAnnouncement}
                />
              </div>
            )}
          {viewAnnouncement && (
            <PreviewAnouncement
              selectedData={selectedData}
              onPrevious={() => {
                setViewAnnouncement(false);
                setSelectedData(undefined);
              }}
              handleDelete={() => {
                // deleteAnnouncement(selectedData?.announcementId);
              }}
              handleEdit={() => {
                // getAnnouncementById(selectedData?.announcementId);
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default Announcements;
