import React, { useState } from "react";
import classes from "./UserAccessItem.module.css";
import { GoDash } from "react-icons/go";
import { BsDashLg } from "react-icons/bs";
import { FaChevronRight } from "react-icons/fa";
import { Box, Grid } from "@mui/material";
import { FiPhoneOutgoing } from "react-icons/fi";
import { amenityNameFormatter } from "../../helpers";
import AssignRoleComponent from "../../pages/user/Management/user-access/AssignRoleComponent";
import UpdateUserAccessModal from "../../modals/UpdateUserAccessModal";

const UserAccessItem = ({ item, onClick }) => {
  const { ammenityName, prefix, unitName, propertyTypeId } = item
    ?.ammenitiesDetail?.length
    ? item?.ammenitiesDetail[0]
    : "";
  return (
    <div className={classes["mainWrapper"]}>
      <div className={classes["header"]}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <div className={classes["userDetailsDiv"]}>
            {item?.ammenitiesDetail?.length ? (
              <p>{amenityNameFormatter(ammenityName, prefix, unitName)}</p>
            ) : (
              <span>Ammenity not assigned</span>
            )}
            <p>{item?.name}</p>
          </div>
        </div>
        {item?.ammenitiesDetail?.length > 0 && (
          <div className={classes["indicators"]}>
            {propertyTypeId === 1 ? (
              <p style={{ color: "var(--green-color)" }}>
                <GoDash size={20} style={{ strokeWidth: "1px" }} />
                Owner
              </p>
            ) : propertyTypeId === 2 ? (
              <p style={{ color: "var(--orange-color)" }}>
                <GoDash size={20} style={{ strokeWidth: "1px" }} /> Tenant
              </p>
            ) : (
              <p style={{ color: "var(--black-color)" }}>
                <GoDash size={20} style={{ strokeWidth: "1px" }} /> Outsider
              </p>
            )}
          </div>
        )}
      </div>
      <div className={classes["accessDiv"]} onClick={() => onClick(item)}>
        <div className={classes["accessDetailsDiv"]}>
          <span>User Access</span>
          {item?.roleName ? (
            <p>{item?.roleName}</p>
          ) : (
            <span style={{ fontWeight: "600", fontSize: "14px" }}>
              Set User Access
            </span>
          )}
        </div>
        <FaChevronRight size={20} />
      </div>

      {/* <AssignRoleComponent
        isIcon={false}
        lists={["test"]}
        setter={(value) => console.log("setter called", value)}
        anchorEle={pointedEle}
        setAnchorEle={setPointedEle}
      /> */}
    </div>
  );
};
export default UserAccessItem;
