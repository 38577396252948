import moment from "moment/moment";
import React from "react";
import { EditIcon, TrashIcon } from "../../assets/icons";
import classes from "./AnnouncementCard.module.css";
import { useState } from "react";
import AreYouSureModal from "../../modals/AreYouSure";
const AnnouncementCard = ({
  data,
  onDeleteAnnouncement,
  onGetAnnouncementById,
  onAnnouncementView,
  onlyDetail = false,
}) => {
  const { status, startDateTime, endDateTime, title, description } = data;
  const decideColor =
    status === "Draft" ? "var(--orange-color)" : "var(--main-color)";
  const [showAreYouSure, setShowAreYouSure] = useState(false);
  return (
    <>
      <div
        className={classes["mainWrapper"]}
        onClick={(e) => {
          // Prevents the event from bubbling up to the parent div
          onAnnouncementView();
          onGetAnnouncementById();
        }}
      >
        {!onlyDetail && (
          <div className={classes.ctaWrapper}>
            <div className={classes.actions}>
              {status !== "Deleted" && (
                <span
                  onClick={(e) => {
                    e.stopPropagation(); // Prevents this event from triggering the parent div's onClick
                    setShowAreYouSure(true);
                  }}
                >
                  <TrashIcon />
                </span>
              )}
              <span
                onClick={(e) => {
                  e.stopPropagation(); // Prevents this event from triggering the parent div's onClick
                  onGetAnnouncementById();
                }}
              >
                <EditIcon />
              </span>
            </div>
          </div>
        )}
        <div className={classes.indicator}>
          <p style={{ color: decideColor }}>{status}</p>
          <p className={classes.date}>
            {moment(startDateTime).format("DD MMM YYYY")}
          </p>
        </div>
        <div className={classes["announcementDetailsDiv"]}>
          <p className="text-in-2-line">{title}</p>
          <p className="text-in-3-line">{description}</p>
        </div>
      </div>
      {showAreYouSure && (
        <AreYouSureModal
          show={showAreYouSure}
          setShow={setShowAreYouSure}
          handler={onDeleteAnnouncement}
        />
      )}
    </>
  );
};

export default AnnouncementCard;
