import React from "react";
import classes from "./NotificationComponent.module.css";
import { defaultProfile } from "../../../../../assets/constant";
const NotificationComponent = ({ data, handler }) => {
  return (
    <div className={classes["main-wrapper"]}>
      <img src={data?.image ? data?.image : defaultProfile} alt="profile" />
      <div>
        <p>
          {data?.message}
          <span onClick={handler}>View Document</span>
        </p>
        <h6>{data?.time}</h6>
      </div>
    </div>
  );
};

export default NotificationComponent;
