import { Checkbox } from "@mui/material";
import React from "react";
import classes from "./MyCheckBox.module.css";

const MyCheckBox = ({ name, value, setter, label, fontSize = 28 }) => {
  return (
    <div className={classes["wrapper"]}>
      <Checkbox
        checked={value}
        name={name}
        onChange={setter}
        sx={{
          "&.Mui-checked": {
            color: "var(--main-color)",
          },
          ".css-i4bv87-MuiSvgIcon-root": {
            fontSize: fontSize,
          },
        }}
      />
      <span>{label}</span>
    </div>
  );
};

export default MyCheckBox;
