import React, { useState } from "react";
import { BsChevronRight } from "react-icons/bs";
import { HiPlusSm } from "react-icons/hi";
import { MdCancel } from "react-icons/md";
import { useSelector } from "react-redux";
import DeleteButton from "../../../../../components/DeleteButton";
import EditApprovalForm from "../../../../../components/EditApprovalForm";
import UpdateAmenityApprovalModal from "../../../../../modals/UpdateAmenityApprovalModal";
import classes from "./EditApproval.module.css";

const EditApproval = ({
  form,
  name,
  roleList,
  typeList,
  designationList,
  disabled,
  handleDelete,
  handleAccept,
  handleReject,
  closePreview,
}) => {
  const [show, setShow] = useState(false);
  const { seriesList } = useSelector((state) => state?.generalApiReducer);

  return (
    <>
      <div className={classes["headerActions"]}>
        <div className={classes["header"]}>
          <MdCancel
            color={"var(--main-color)"}
            size={40}
            onClick={closePreview}
          />
          <p>{name}</p>
        </div>
        {/* <MyCheckBox
          name={"active"}
          value={true}
          label={form?.values?.active ? "Active" : "InActive"}
        /> */}
        <div className={classes["actionsWrapper"]}>
          <DeleteButton handler={handleDelete} />
        </div>
      </div>
      {form?.values?.propertyTypeId?.value !== 3 && (
        <AmenityDetailsCard
          amenityDetails={form?.values}
          handleClick={() => setShow(true)}
        />
      )}
      {form?.values && (
        <EditApprovalForm
          form={form}
          roleList={roleList}
          typeList={typeList}
          designationList={designationList}
          handleAccept={handleAccept}
          handleReject={handleReject}
        />
      )}
      <UpdateAmenityApprovalModal
        show={show}
        setShow={setShow}
        form={form}
        seriesList={seriesList}
      />
    </>
  );
};

export const AmenityDetailsCard = ({ amenityDetails, handleClick }) => {
  return (
    <div className={classes["userDetailsWrapper"]} onClick={handleClick}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <div className={classes["userDetailsDiv"]}>
            <p>Address</p>
            <p>{amenityDetails?.communitySeriesDetailId?.label}</p>
          </div>
        </div>
        {amenityDetails?.ammenityName ? (
          <BsChevronRight
            style={{
              float: "right",
              strokeWidth: "2px",
              color: "var(--black-color)",
            }}
          />
        ) : (
          <HiPlusSm size={30} color="var(--black-color)" />
        )}
      </div>
    </div>
  );
};

export default EditApproval;
