import React from "react";
import classes from "./MaintenanceDetailsCard.module.css";
import MyButton from "../../../../components/MyButton";
import { CiSquareCheck } from "react-icons/ci";
import { FaCheck } from "react-icons/fa6";
import { ImCheckboxUnchecked } from "react-icons/im";

const MaintenanceDetailsCard = ({ isReceived = false, data, receivedData }) => {
  const { maintenance, penalty, maintenanceMonth } = data;
  const total = maintenance + penalty;
  const { receivedMaintenance } = receivedData;
  const remainingDues = total - receivedMaintenance;
  return (
    <div className={classes["cardMainWrapper"]}>
      <div className={classes["detailsWrapper"]}>
        <div className={classes["userDetailsDiv"]}>
          <p>{isReceived ? total : maintenance}</p>
          <p>Total maintenance</p>
          {!isReceived && (
            <div className={classes["orangeCardsWrapper"]}>
              {maintenanceMonth.map((item) => (
                <Indicators text={`Month of ${item}`} received={isReceived} />
              ))}
            </div>
          )}
        </div>
        {!isReceived ? (
          penalty > 0 && (
            <div className={classes["userDetailsDiv"]}>
              <p>{penalty}</p>
              <p>Penalty surcharge</p>
              <div className={classes["orangeCardsWrapper"]}>
                <Indicators text={"Wave"} received={false} checked={false} />
              </div>
            </div>
          )
        ) : (
          <div className={classes["userDetailsDiv"]}>
            <p style={{ color: "var(--green-color)" }}>{receivedMaintenance}</p>
            <p>Maintenance received by Saad</p>
            <div className={classes["orangeCardsWrapper"]}>
              {maintenanceMonth.map((item) => (
                <Indicators text={`Month of ${item}`} received={isReceived} />
              ))}
            </div>
          </div>
        )}
        {isReceived ? (
          <div className={classes["userDetailsDiv"]}>
            <p>{remainingDues}</p>
            <p>remaining amount added in dues</p>
          </div>
        ) : (
          <div className={classes["userDetailsDiv"]}>
            <p>{total}</p>
            <p>Total Maintenance</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default MaintenanceDetailsCard;

const Indicators = ({ received, text, checked = true }) => {
  return (
    <MyButton
      className={[
        classes["extraBtnStyle"],
        (received || !checked) && classes["received"],
      ].join(" ")}
    >
      {text}
      {checked ? (
        received ? (
          <FaCheck size={15} style={{ strokeWidth: "1px" }} />
        ) : (
          <CiSquareCheck size={20} style={{ strokeWidth: "1px" }} />
        )
      ) : (
        <ImCheckboxUnchecked size={15} />
      )}
    </MyButton>
  );
};
