import { LuInfo } from "react-icons/lu";
import MyButton from "../../components/MyButton";
import Classes from "./AddFinancialYearModal.module.css";
import Modal from "@mui/material/Modal";
import React from "react";
import MySelect from "../../components/MySelect";
import MyInput from "../../components/MyInput";
import MySearchSelect from "../../components/MySearchSelect";

const AddFinancialYearModal = ({
  show,
  setShow,
  form,
  setForm,
  handler,
  title = "Select Financial Year",
}) => {
  return (
    <>
      <style>{`
      .modal-sm {
        --bs-modal-width: 450px;
      }
      .modal-content{
        border-radius: 16px;
      }
    `}</style>
      <Modal
        open={show}
        onClose={setShow}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <div className={Classes.container}>
          <div className={Classes.description}>
            <p>{title}</p>
          </div>
          <div className={Classes.inputWrapper}>
            <MyInput
              type="date"
              label={"Select Start Date"}
              name={"from"}
              value={form?.from}
              setter={(e) =>
                setForm((prev) => ({ ...prev, from: e.target.value }))
              }
            />
            <MyInput
              type="date"
              label={"Select End Date"}
              name={"to"}
              value={form?.to}
              setter={(e) =>
                setForm((prev) => ({ ...prev, to: e.target.value }))
              }
            />
          </div>

          <div className={Classes.actionsWrapper}>
            <MyButton
              btnText={"Create"}
              handler={() => {
                setShow(false);
                if (typeof handler == "function") {
                  handler();
                }
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddFinancialYearModal;
