import React from "react";
import classes from "./DeleteButton.module.css";
import { RiDeleteBin5Line } from "react-icons/ri";

const DeleteButton = ({ handler }) => {
  return (
    <div className={[classes.addMenuBtn, "c-p"].join(" ")} onClick={handler}>
      <RiDeleteBin5Line color="var(--secondary-color)" size={20} />
    </div>
  );
};

export default DeleteButton;
