import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useApi from "./useApi";
import useLoader from "./useLoader";
import { useSelector } from "react-redux";

const useAnnouncement = () => {
  const { communityId } = useSelector((state) => state?.authReducer);
  const { loading, setLoading } = useLoader();
  const announcementApi = useApi();
  const [announcements, setAnnouncements] = useState([]);
  const [selectedData, setSelectedData] = useState(undefined);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [visible, setVisible] = useState(0);
  const [totalCount, setTotalCount] = useState(0); // Total number of rows from API
  // Fetch announcements with pagination
  const pageSize = 10;
  // Fetch announcements with pagination
  const getAllAnnouncementListing = async (status = "", pageNumber = 1) => {
    if (loading) return; // Avoid multiple requests at once

    setLoading(true);

    const result = await announcementApi?.getAllAnnouncementListing({
      data: {
        pageNumber,
        pageSize,
        totalRowsCount: 0,
        sortField: "",
        sortDirection: "",
        search: "",
        filter: { status, communityId },
      },
    });

    if (result?.data?.headers?.code === 200) {
      const newAnnouncements = result?.data?.body?.data || [];
      const totalRows = result?.data?.body?.pagination?.totalRowsCount || 0;

      setTotalCount(totalRows);
      // Append new announcements to the list
      setAnnouncements((prev) => {
        return [...newAnnouncements];
      });

      // If fewer items are returned than pageSize, no more pages are available
    }

    setLoading(false);
  };

  useEffect(() => {
    getAllAnnouncementListing("", page);
  }, [page]);

  // Adjusted handleOnScroll function with a 100px buffer

  const deleteAnnouncement = async (id) => {
    setLoading(true);
    const response = await announcementApi.deleteAnnouncement({
      id,
      communityId,
    });
    if (response?.data?.headers?.code == 200) {
      setLoading(false);
      return toast.success("Announcement Deleted Successfully");
    }
  };
  const getAnnouncementById = async (id) => {
    setLoading(true);
    const response = await announcementApi?.getAnnouncementById(id);
    if (response?.data?.headers?.code == 200) {
      setSelectedData(response?.data?.body);
    }
    setLoading(false);
  };

  // const fetchAllRole = async (setter) => {
  //   setLoading(true);
  //   const response = await announcementApi?.GetAllRole();
  //   if (response != undefined) {
  //     console.log("response", response?.data);
  //     setter(response?.data?.body);
  //   }
  //   setLoading(false);
  // };

  const addOrEditAnnouncement = async (params) => {
    setLoading(true);
    const response = await announcementApi?.addOrEditAnnouncement(
      selectedData,
      { ...params, communityId }
    );
    if (response?.data?.headers?.code == 200) {
      if (selectedData) {
        toast.success("Announcement Updated Successfully");
      } else {
        toast.success("Announcement Created Successfully");
      }
      setSelectedData(undefined);
      getAllAnnouncementListing();
    }
    setLoading(false);
  };

  return {
    announcements,
    selectedData,
    setSelectedData,
    getAnnouncementById,
    getAllAnnouncementListing,
    deleteAnnouncement,
    addOrEditAnnouncement,
    // fetchAllRole,
    totalCount,
    visible,
    page,
  };
};

export default useAnnouncement;
