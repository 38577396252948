import React, { useState, useEffect } from "react";
import classes from "./Amenities.module.css";
import PaddingContainer from "../../../../components/PaddingContainer";
import Header from "../../../../components/Header";
import AmenitiesSettingsCard from "./AmenitiesSettingsCard";
import { filteredDataHandler } from "../../../../helpers";
import useGeneralApi from "../../../../Hooks/useGeneralApi";
import Loader from "../../../../components/Loader";
import EditAmenity from "./EditAmenity";
import useAmenity from "../../../../Hooks/useAmenity";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const Amenities = () => {
  // const { amenitiesList, setAmenitiesList } = useAmenity();
  const { amenitiesList } = useSelector((state) => state?.generalApiReducer);

  const [showEdit, setShowEdit] = useState(false);
  const [form, setForm] = useState({});

  const previewSeriesHandler = (data) => {
    setShowEdit(true);
    setForm({
      ...data,
      communityAmmenitySeriesList: data?.communityAmmenitySeriesList?.map(
        (item, index) => ({
          ...item,
          ammenityType: data?.ammenityType,
          seriesStart: item?.seriesFrom,
          seriesEnd: item?.seriesTo,
          name: item?.unitName,
        })
      ),
    });
  };

  const handleAddSeries = (id) => {
    setForm((prev) => ({
      ...prev,
      communityAmmenitySeriesList: [
        ...prev?.communityAmmenitySeriesList,
        { id: prev.communityAmmenitySeriesList?.length + 1 },
      ],
    }));
  };

  const handleRemoveSeries = (pid = 0, id) => {
    setForm((prev) => ({
      ...prev,
      communityAmmenitySeriesList: prev.communityAmmenitySeriesList.filter(
        (ele) => ele.id !== id
      ),
    }));
  };

  const handleDataChange = (pId = 0, e, id) => {
    const { name, value } = e.target;
    const updatedData = form.communityAmmenitySeriesList.map((item) =>
      item?.id && item.id === id
        ? {
            ...item,
            [name]: value,
          }
        : item
    );
    setForm((prev) => ({
      ...prev,
      communityAmmenitySeriesList: updatedData,
    }));
  };

  const handleSubmit = () => {
    toast.warn("In Progress");
  };
  const cancelHandler = () => {
    setForm({});
    setShowEdit(false);
  };

  return (
    <>
      <Header title={"Amenities"} />
      {amenitiesList.length <= 0 ? (
        <Loader />
      ) : (
        <>
          {!showEdit ? (
            <div className={classes["listWrapper"]}>
              {amenitiesList?.length > 0 &&
                amenitiesList?.map((item, index) => (
                  <AmenitiesSettingsCard
                    name={item?.ammenityName}
                    count={item?.totalCount}
                    handler={() => previewSeriesHandler(item)}
                  />
                ))}
            </div>
          ) : (
            <EditAmenity
              form={form}
              handleAddSeries={handleAddSeries}
              handleRemoveSeries={handleRemoveSeries}
              handleChange={handleDataChange}
              handleSubmit={handleSubmit}
              cancelHandler={cancelHandler}
            />
          )}
        </>
      )}
    </>
  );
};

export default Amenities;
