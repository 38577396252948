import React, { useState } from "react";
import classes from "./ItemCard.module.css";
import { GoDash } from "react-icons/go";
import { BsDashLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import MyCheckBox from "../../../../components/MyCheckBox";

const ItemCard = ({ data, handler, selectAll }) => {
  const navigate = useNavigate();
  return (
    <div className={classes["mainWrapper"]}>
      {data?.map((item, index) => (
        <>
          <div className={classes["header"]}>
            <p>{item?.name}</p>
            <div className={classes["indicators"]}>
              <MyCheckBox name={"selectAll"} setter={selectAll} />
            </div>
          </div>
          <div className={classes["layout"]}>
            {item?.flatNo?.map((item1, index) => (
              <div
                className={[
                  classes["flatItem"],
                  item1.selected && classes["selected"],
                ].join(" ")}
                onClick={() => handler(item?.id, item1?.no)}
              >
                {item1.no}
              </div>
            ))}
          </div>
        </>
      ))}
    </div>
  );
};
export default ItemCard;
