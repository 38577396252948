import { combineReducers } from "redux";
import authSlice from "./authSlice";
import loadingSlice from "./loadingSlice";
import generalApiSlice from "./generalApiSlice";

const rootReducer = combineReducers({
  authReducer: authSlice,
  loadingReducer: loadingSlice,
  generalApiReducer: generalApiSlice,
});

export default rootReducer;
