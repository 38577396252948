import React, { useState } from "react";
import classes from "./ExpenseDetailsPage.module.css";
import { useParams, useNavigate } from "react-router-dom";
import PaddingContainer from "../../../../components/PaddingContainer";
import HeaderAction from "../../../../components/HeaderAction";
import MyButton from "../../../../components/MyButton";
import { FaChevronRight } from "react-icons/fa";
import FilterTabs from "../../../../components/FilterTabs";

const ExpenseDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({
    id: 1,
    name: "Lift Maintenance",
    expenseList: [
      {
        id: 11,
        name: "Haris Ali",
        amount: 1000,
        date: "11 Dec 2023",
      },
      {
        id: 12,
        name: "Saad Ashraf",
        amount: 2000,
        date: "11 Dec 2023",
      },
      {
        id: 13,
        name: "Owais Niaz",
        amount: 3000,
        date: "11 Dec 2023",
      },
    ],
  });
  const tabs = [
    {
      name: "All",
      slug: "all",
    },
    {
      name: "Weekly",
      slug: "weekly",
    },
    {
      name: "Monthly",
      slug: "monthly",
    },
    {
      name: "Yearly",
      slug: "yearly",
    },
    {
      name: "Date",
      slug: "date",
    },
  ];
  const [activeFilters, setActiveFilters] = useState({
    all: true,
    monthly: false,
    weekly: false,
    yearly: false,
    date: false,
  });
  const handleFilterChange = (name) => {
    setActiveFilters((prev) => ({
      ...prev,
      [name]: !{ ...prev }[name],
    }));
  };

  return (
    <>
      <HeaderAction title={data?.name} onClick={() => navigate(-1)} />
      <FilterTabs
        tabs={tabs}
        activeFilters={activeFilters}
        handleFilterChange={handleFilterChange}
      />
      <div className={classes["itemsWrapper"]}>
        {data?.expenseList?.map((item, index) => (
          <Card data={item} />
        ))}
      </div>
      <MyButton className={classes["extraBtnStyle"]} handler={() => {}}>
        <span className={classes["btnHeading"]}>Add Expense</span>
        <FaChevronRight />
      </MyButton>
    </>
  );
};

export default ExpenseDetailsPage;

const Card = ({ data }) => {
  const { name, amount, date } = data;
  return (
    <div className={classes["cardWrapper"]}>
      <div className={classes["leftWrapper"]}>
        <h1>{date}</h1>
        <h2>{name}</h2>
      </div>
      <span className={classes["amountSpan"]}>{amount}</span>
    </div>
  );
};
