import useApi from "./useApi";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { otpReducer } from "../store/authSlice";
import { toast } from "react-toastify";

const useAccounts = () => {
  const api = useApi();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [accountDetails, setAccountDetails] = useState([
    {
      accountId: 1,
      yearStart: "Dec 2022",
      yearEnd: "Dec 2023",
      totalBalance: 0,
      totalReceived: 0,
      totalExpense: 0,
      expenseCategoryList: [
        {
          categoryId: 1,
          name: "Indirect Expense",
          expenseList: [
            {
              expenseId: 1,
              expenseName: "Lift Maintenance",
            },
            {
              expenseId: 2,
              expenseName: "Guard Salary",
            },
          ],
        },
      ],
    },
  ]);

  return {};
};

export default useAccounts;
