import React from "react";
import classes from "./DashboardCard.module.css";
import { BsChevronRight } from "react-icons/bs";

const DashboardCard = ({ count, text, handler }) => {
  return (
    <div className={classes["mainWrapper"]} onClick={handler}>
      <BsChevronRight
        style={{
          float: "right",
          strokeWidth: "2px",
          color: "var(--secondary-color)",
        }}
      />
      <h1>{count}</h1>
      <h2>{text}</h2>
    </div>
  );
};

export default DashboardCard;
