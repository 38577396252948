import { Box, FormControlLabel, Radio, RadioGroup, Grid } from "@mui/material";
import React from "react";
import MyInput from "../MyInput";
import { RiDeleteBin6Line } from "react-icons/ri";
import AmenityAddComponent from "../AmmenityAddComponent";

const AmenitiesRightComponent = ({
  styles,
  amenitiesArr,
  handleChange,
  zoneArrHandler,
  handleAddSeries,
  handleRemoveSeries,
}) => {
  return (
    <>
      {amenitiesArr && (
        <Box
          sx={{
            "& .MuiTextField-root": { my: 1, ml: 0, width: "100%" },
          }}
          className={styles.formComponent}
        >
          {/* {amenitiesArr.map((item, index) => ( */}
          <AmenityAddComponent
            item={amenitiesArr}
            handleChange={handleChange}
            handleAddSeries={handleAddSeries}
            handleRemoveSeries={handleRemoveSeries}
            styles={styles}
          />
          {/* ))} */}
        </Box>
      )}
    </>
  );
};

export default AmenitiesRightComponent;
