// export const apiUrl = "http://23.227.206.234:3015";
export const apiUrl = "https://cb.qplnk.com";
export const imageUrl = (url) => `${apiUrl}/api/images/${url}`;
/**
 *
 * @param {String} link
 * @returns {String}
 */
export const BaseURL = (link) => `${apiUrl}/api/${link}`;

export const apiHeader = (token, isFormData, additionalOptions = {}) => {
  if (token && !isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
  } else if (token && isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      ...additionalOptions,
    };
  } else if (!token && !isFormData) {
    return {
      headers: {
        "Content-Type": "application/json",
      },
    };
  } else if (!token && isFormData) {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  }
};
