import React from "react";
import Modal from "@mui/material/Modal";
import classes from "./Logout.module.css";
import MyButton from "../../components/MyButton";
import { SlLogout } from "react-icons/sl";
import CommonCard from "../../components/CommonCard";

const LogoutModal = ({ show, setShow, handler }) => {
  return (
    <>
      <style>{`
      .modal-sm {
        --bs-modal-width: 350px;
      }
      .modal-content{
        border-radius: 16px;
      }
    `}</style>
      <Modal
        open={show}
        onClose={setShow}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <div className={classes["container"]}>
          <div className={classes["heading-wrapper"]}>
            <div className={classes["icon-wrapper"]}>
              <SlLogout size={30} color="#ffff" style={{ marginLeft: -5 }} />
            </div>
            <h1>Logout</h1>
            <p>Are you sure you want to log out from your account?</p>
          </div>
          <div className={classes["action-wrapper"]}>
            <MyButton
              btnText="Cancel"
              type="button"
              className={classes["cancel-button"]}
              handler={setShow}
            />
            <MyButton
              btnText="Confirm"
              handler={handler}
              className={classes["signIn-button"]}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LogoutModal;
