import React from "react";
import BookingItemCard from "../BookingItemCard";
import classes from "./BookingListing.module.css";

const BookingListing = ({
  title,
  bookingData,
  activeFilters,
  navigateHandler,
}) => {
  return (
    <>
      <div className={classes["subSection"]}>
        <div className={classes["members-heading"]}>
          <h1>{title} List</h1>
          <p>
            {bookingData?.length < 9
              ? `0${bookingData?.length}`
              : bookingData?.length}{" "}
            Records
          </p>
        </div>
        {bookingData?.map((item, index) => (
          <BookingItemCard item={item} key={index} onClick={navigateHandler} />
        ))}
      </div>
    </>
  );
};

export default BookingListing;
