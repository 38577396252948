import React from "react";
import { profileHexagon } from "../../../assets/constant";
import OverviewItem from "../../../components/OverviewItem";
import styles from "./RightAside.module.css";

const RightAside = ({ sidebarRightAside, isSidebarClose, classes }) => {
  const user = {
    username: "Saad Ashraf",
    emailAddress: "saadashraf123@gmail.com",
  };
  const notificationsData = [
    {
      name: "Amaan Ali",
      description: "maintenance collected from A-308",
      time: "2 min",
    },
    {
      name: "Paras",
      description: "approval request",
      time: "2 hour",
    },
    {
      name: "Umair",
      description: "waved amount for A-404",
      time: "3 min ago",
    },
  ];

  const membersData = [
    {
      name: "Ali Ur Rehman",
      description: "joined community",
      time: "2 min",
    },
    {
      name: "Muhammad Owais",
      description: "joined community",
      time: "2 min",
    },
    {
      name: "Sania",
      description: "joined community",
      time: "2 min",
    },
    {
      name: "Sania",
      description: "joined community",
      time: "2 min",
    },
    {
      name: "Sania",
      description: "joined community",
      time: "2 min",
    },
    {
      name: "Sania",
      description: "joined community",
      time: "2 min",
    },
  ];
  const tagName =
    user?.username?.split(" ")[0]?.charAt(0) +
    user?.username?.split(" ")[1]?.charAt(0);

  return (
    <aside
      className={[
        classes["sidebar-side-transition"],
        classes["sidebar__sideContent"],
        isSidebarClose && classes["width-0"],
        styles["customStyles"],
      ].join(" ")}
      ref={sidebarRightAside}
    >
      <div className={styles["profileWrappper"]}>
        <span>{tagName}</span>
        <img src={profileHexagon} />
        <div className={styles["userDetails"]}>
          <h1>{user?.username}</h1>
          <h4>{user?.emailAddress}</h4>
        </div>
      </div>
      <div className={styles["notificationSection"]}>
        <div className={styles["heading"]}>
          <h2>Notifications</h2>
          <p>See all</p>
        </div>
        <div className={styles["mainWrapper"]}>
          {notificationsData?.map((item, index) => (
            <OverviewItem
              key={index}
              name={item.name}
              description={item.description}
              time={item.time}
            />
          ))}
        </div>
      </div>
      <div className={styles["memberSection"]}>
        <div className={styles["heading"]}>
          <h2>New Members</h2>
          <p>See all</p>
        </div>
        <div className={styles["mainWrapper"]}>
          {membersData?.map((item, index) => (
            <OverviewItem
              key={index}
              name={item.name}
              description={item.description}
              time={item.time}
            />
          ))}
        </div>
      </div>
    </aside>
  );
};

export default RightAside;
