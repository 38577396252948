import React from "react";

export const EditIcon = (props) => (
  <svg
    width={props.width || "18"}
    height={props.height || "17"}
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.71387 3.00042H3.42815C2.48138 3.00042 1.71387 3.76793 1.71387 4.71471V14.1433C1.71387 15.0901 2.48138 15.8576 3.42815 15.8576H12.8567C13.8035 15.8576 14.571 15.0901 14.571 14.1433V9.85756M13.3588 1.78824C14.0283 1.11877 15.1137 1.11877 15.7832 1.78824C16.4527 2.45771 16.4527 3.54313 15.7832 4.2126L8.42395 11.5718H5.99958L5.99958 9.14748L13.3588 1.78824Z"
      stroke="#F8FFFF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);



export const TrashIcon = (props) => (
  <svg
    width={props.width || "21"}
    height={props.height || "20"}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.3335 5.83333L15.6107 15.9521C15.5484 16.8243 14.8227 17.5 13.9483 17.5H7.05203C6.17763 17.5 5.4519 16.8243 5.3896 15.9521L4.66683 5.83333M8.8335 9.16667V14.1667M12.1668 9.16667V14.1667M13.0002 5.83333V3.33333C13.0002 2.8731 12.6271 2.5 12.1668 2.5H8.8335C8.37326 2.5 8.00016 2.8731 8.00016 3.33333V5.83333M3.8335 5.83333H17.1668"
      stroke="#F8FFFF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

