import React from "react";
import MyInput from "../../../../../components/MyInput";
import { Box, Grid } from "@mui/material";
import classes from "./PasswordSettings.module.css";

const PasswordSettings = ({ form, handleChange }) => {
  return (
    <>
      <Box
        sx={{
          "& .MuiTextField-root": { my: 1, ml: 0, width: "100%" },
        }}
        className={classes["formWrapper"]}
        noValidate
        autoComplete="off"
      >
        <MyInput
          name="oldPassword"
          label="Old Password"
          value={form?.oldPassword}
          setter={handleChange}
        />
        <span className={classes["forgetSpan"]}>Forget Old Password</span>
        <MyInput
          name="newPassword"
          label="New Password"
          value={form?.newPassword}
          setter={handleChange}
        />
        <MyInput
          name="confirmNewPassword"
          label="Confirm Password"
          value={form?.confirmNewPassword}
          setter={handleChange}
        />
      </Box>
    </>
  );
};

export default PasswordSettings;
