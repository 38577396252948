import { useFormik } from "formik";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import HeaderAction from "../../../../../components/HeaderAction";
import DateField from "../../../../../components/DateField";
import DeleteButton from "../../../../../components/DeleteButton";
import MyButton from "../../../../../components/MyButton";
import MyCheckBox from "../../../../../components/MyCheckBox";
import MyInput from "../../../../../components/MyInput";
import MySelect from "../../../../../components/MySelect";
import useGeneralApi from "../../../../../Hooks/useGeneralApi";
import { announcementSchema } from "../../../../../schema";
import classes from "./AddOrEditAnouncement.module.css";
import { useSelector } from "react-redux";

const initialValues = {
  title: "",
  description: "",
  startDateTime: "",
  endDateTime: "",
  status: "recent",
  announcementFor: [],
  isActive: true,
};

const AddOrEditAnnouncement = ({ onFormSubmit, onPrevious, selectedData }) => {
  const { roleList } = useSelector((state) => state?.generalApiReducer);

  const isEditMode = selectedData !== null; // Check if it's edit mode
  const formik = useFormik({
    initialValues,
    validationSchema: announcementSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const params = {
          ...(selectedData && { announcementId: selectedData?.announcementId }),
          ...values,
          announcementFor:
            roleList?.length == values?.announcementFor?.length
              ? []
              : values?.announcementFor?.map((e) => e?.value),
        };
        onFormSubmit(params);
      } catch (error) {
        toast.error("Error submitting announcement");
      }
    },
  });

  const {
    touched,
    errors,
    values,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    setValues,
  } = formik;

  const handleChangeWithTouch = (field) => (value) => {
    setFieldValue(field, value);
    setFieldTouched(field, true, false);
  };
  useEffect(() => {
    if (selectedData) {
      // Populate form with selectedData values when editing
      setValues({
        title: selectedData?.title || "",
        description: selectedData?.description || "",
        startDateTime: selectedData.startDateTime || "",
        endDateTime: selectedData.endDateTime || "",
        announcementFor:
          selectedData.announcementFor?.map((role) => ({
            label: roleList?.find((r) => r.roleId === role)?.roleName || role,
            value: role,
          })) || [],
        status: "Recent",
        isActive: selectedData.isActive || false,
      });
    }
  }, [selectedData, roleList, setValues]);

  return (
    <>
      <HeaderAction
        onClick={onPrevious}
        title={`${selectedData ? "Edit" : "Add"} Announcement`}
      />
      <form
        onSubmit={handleSubmit}
        className={classes["formWrapper"]}
        noValidate
        autoComplete="off"
      >
        <div className={classes["headerActions"]}>
          <MyCheckBox
            name={"active"}
            value={values.isActive}
            setter={() => {
              handleChangeWithTouch("isActive")(!values?.isActive);
            }}
            label={values?.isActive ? "Active" : "Inactive"}
          />
          {isEditMode && <DeleteButton />} {/* Show delete only in edit mode */}
        </div>

        <DateField
          value={values?.startDateTime}
          setter={handleChangeWithTouch("startDateTime")}
          label={"Start Date Time"}
          error={touched?.startDateTime && errors?.startDateTime}
        />
        <MyInput
          name="title"
          label="Announcement Title"
          value={values?.title}
          setter={(e) => handleChangeWithTouch("title")(e.target.value)}
          error={touched?.title && errors?.title}
        />
        <MyInput
          name="description"
          label="Announcement Description"
          value={values?.description}
          setter={(e) => handleChangeWithTouch("description")(e.target.value)}
          error={touched?.description && errors?.description}
        />
        <MySelect
          name="role"
          label="User Role"
          value={values?.announcementFor}
          multiple={true}
          setter={(value) => {
            handleChangeWithTouch("announcementFor")(value);
          }}
          options={roleList?.map((item) => ({
            label: item?.roleName,
            value: item?.roleId,
          }))}
          error={touched?.announcementFor && errors?.announcementFor}
        />
        <DateField
          value={values?.endDateTime}
          setter={handleChangeWithTouch("endDateTime")}
          label={"End Date Time"}
        />
        <div className={classes.ctaWrapper}>
          <div className={classes.cta}>
            <MyButton
              type="submit"
              btnText={isEditMode ? "Update" : "Add"}
              className={classes.saveBtn}
            />
            {!isEditMode && (
              <MyButton
                type="button"
                btnText={"Draft"}
                className={classes.draftBtn}
                onClick={() => {
                  handleChangeWithTouch("status")("draft");
                  handleSubmit();
                }}
              />
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default AddOrEditAnnouncement;
