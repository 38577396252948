import React, { useState } from "react";
import classes from "./MemberItemCard.module.css";
import { GoDash } from "react-icons/go";
import { BsDashLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import { Box, Grid } from "@mui/material";
import MyInput from "../MyInput";
import { FiPhoneOutgoing } from "react-icons/fi";

const MemberItemCard = ({ item, ammenityDetails, onClick, contact }) => {
  return (
    <div className={classes["mainWrapper"]}>
      <div className={classes["header"]} onClick={() => onClick(item)}>
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <div className={classes["detailsWrapper"]}>
            <div className={classes["userDetailsDiv"]}>
              <p>
                {ammenityDetails?.ammenityName} {ammenityDetails?.prefix}-
                {ammenityDetails?.unitName}
              </p>
              <p>{item?.name}</p>
            </div>
            <h5>{item?.roleName}</h5>
          </div>
          <FaChevronRight size={20} />
        </div>
        <div
          className={[
            classes["indicators"],
            classes[`${ammenityDetails?.propertyTypeName}`],
          ].join(" ")}
        >
          <p>
            <GoDash size={20} style={{ strokeWidth: "1px" }} />
            {ammenityDetails?.propertyTypeName}
          </p>
        </div>
      </div>
      {contact && (
        <Box
          component="form"
          // onSubmit={handleSubmit}
          sx={{
            "& .MuiTextField-root": { my: 1, ml: 0, width: "100%" },
          }}
          className={classes["formWrapper"]}
          noValidate
          autoComplete="off"
        >
          <MyInput
            name="contact"
            label="Contact Number"
            value={item?.cellNo}
            readOnly={true}
            leftIcon={<FiPhoneOutgoing size={25} color="var(--black-color)" />}
          />
        </Box>
      )}
    </div>
  );
};
export default MemberItemCard;
