import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FilterButton from "../../../../components/FilterButton";
import Header from "../../../../components/Header";
import Loader from "../../../../components/Loader";
import PaddingContainer from "../../../../components/PaddingContainer";
import SearchBox from "../../../../components/SearchBox";
import useGeneralApi from "../../../../Hooks/useGeneralApi";
// import AddRole from "./add-role";
import UserAccessItem from "../../../../components/UserAccessItem";
import useLoader from "../../../../Hooks/useLoader";
import useUserAccess from "../../../../Hooks/useUserAccess";
import UpdateUserAccessModal from "../../../../modals/UpdateUserAccessModal";
import classes from "./user-access.module.css";
import useInfiniteScroll from "../../../../Hooks/useInfiniteScroll";
import MiniLoader from "../../../../components/MiniLoader";

const UserAccess = () => {
  const accessToken = useSelector((state) => state?.authReducer?.accessToken);
  const { roleList } = useSelector((state) => state?.generalApiReducer);
  const { loading } = useLoader();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    form,
    setForm,
    userListing,
    openUpdate,
    setOpenUpdate,
    setUserHandler,
    submitHandler,
    cancelHandler,
    observerRef,
    isLoading,
  } = useUserAccess();

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header title={"User Access"} />
          <div className={classes["tabs-section"]}>
            <FilterButton text={"User Role"} />
            <FilterButton text={"User Access"} active={true} />
            <FilterButton text={"President"} />
            <FilterButton text={"Supervisor"} />
          </div>
          <div className={classes["subSection"]}>
            <div className={classes["members-heading"]}>
              <h1>Users</h1>
              <p>
                {userListing?.length < 9
                  ? `0${userListing?.length}`
                  : userListing?.length}{" "}
                Records
              </p>
            </div>
            {userListing?.map((item, index) => (
              <UserAccessItem
                item={item}
                key={index}
                onClick={setUserHandler}
              />
            ))}
            <div ref={observerRef}></div> {isLoading && <MiniLoader />}
          </div>
        </>
      )}
      <UpdateUserAccessModal
        show={openUpdate}
        setShow={() => setOpenUpdate(false)}
        handler={submitHandler}
        cancelHandler={cancelHandler}
        form={form}
        handleChange={(name, val) =>
          setForm((prev) => ({ ...prev, [name]: val }))
        }
        roleList={roleList}
      />
    </>
  );
};

export default UserAccess;
