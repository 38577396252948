import React, { useState, useEffect, useRef } from "react";
import { Grid, Box } from "@mui/material";
import MyButton from "../../../../components/MyButton";
import { hidePhoneNumber } from "../../../../helpers";
import { MuiOtpInput } from "mui-one-time-password-input";
import styles from "./OTPScreen.module.css";
import CommonCard from "../../../../components/CommonCard";

const OTPScreen = ({
  otp,
  handleChange,
  handleSubmit,
  data,
  error,
  handleFormSubmit = null,
}) => {
  const [timeLeft, setTimeLeft] = useState(120);
  const formRef = useRef();

  // useEffect(() => {
  //   if (otp?.length === 4) {
  //     const event = new Event("submit", { cancelable: true });
  //     formRef.current.dispatchEvent(event);
  //     handleSubmit(event);
  //   }
  // }, [otp]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (timeLeft == 0) {
        return;
      }
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  return (
    <Grid xs={12} className={[styles.wrapper, styles.firstWrapper].join(" ")}>
      <CommonCard className={[styles.mainWrapper, styles.mainStyles].join(" ")}>
        <div className={styles.header}>
          <h1>Verify your account</h1>
          <h2>
            Enter the 4-digit PIN code sent to your Phone{" "}
            <span>
              {data?.phoneNumber &&
                `${data?.code}${hidePhoneNumber(data?.phoneNumber)}`}
            </span>
          </h2>
        </div>
        <Box
          ref={formRef}
          sx={{
            "& .MuiTextField-root": { my: 1, ml: 0, width: "100%" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            position: "relative",
          }}
          className={styles.formComponent}
          noValidate
          autoComplete="off"
        >
          <div className={styles.otpWrapper}>
            <MuiOtpInput
              TextFieldsProps={{ size: "medium" }}
              autoFocus
              value={otp}
              onChange={handleChange}
              sx={{
                "& .css-1tq8nf4-MuiFormControl-root-MuiTextField-root input": {
                  color: "var(--main-color) !important",
                },
                "& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: "2px solid var(--main-color-dark) !important",
                  },
                "& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    border: "1px solid var(--main-color-dark) !important",
                  },
                gap: 1,
              }}
            />
            {error && (
              <span className={styles.errorMsg}>
                Wrong OTP, Please try again*
              </span>
            )}

            <div>
              {timeLeft == 0 ? (
                <span onClick={() => setTimeLeft(5)} className={styles.resend}>
                  Resend otp
                </span>
              ) : (
                <p className={styles.counter}>
                  Time left: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                </p>
              )}
            </div>
          </div>
          <div className={styles.btnWrapper}>
            <MyButton
              btnText={"Verify"}
              className={[
                styles.extraBtnStyle,
                otp.length !== 4 && styles.disabled,
              ].join(" ")}
              disabled={otp.length !== 4}
              handler={handleSubmit}
            />
          </div>
        </Box>
      </CommonCard>
    </Grid>
  );
};

export default OTPScreen;
