import React from "react";
import classes from "./BookingItemCard.module.css";
import {
  dateDifference,
  dateFromToFormatter,
  timeFromToFormatter,
} from "../../helpers";
import moment from "moment";

const BookingItemCard = ({ item, onClick }) => {
  const remainingAmount = item?.bookingAmount - item?.advanceAmount;

  return (
    <div className={classes["mainWrapper"]} onClick={() => onClick(item)}>
      <div className={classes["header"]}>
        <p className={classes["bookingId"]}>{item?.bookingNo}</p>
        <div className={classes["dateTimeWrapper"]}>
          <p>{dateFromToFormatter(item?.fromDateTime, item?.toDateTime)}</p>
          <p>{timeFromToFormatter(item?.fromDateTime, item?.toDateTime)}</p>
        </div>
      </div>
      <div className={classes["bodyWrapper"]}>
        <div className={classes["detailsWrapper"]}>
          <p className={classes["title"]}>{item?.eventTitle}</p>
          <p className={classes["bookerName"]}>{item?.name}</p>
          <div className={classes["amountWrapper"]}>
            <p>{remainingAmount}</p>
            <p>Total Dues</p>
          </div>
        </div>
        <p className={classes["indicator"]}>
          {dateDifference(item?.fromDateTime) === 0
            ? "Today"
            : dateDifference(item?.fromDateTime) < 0
            ? `After ${dateDifference(item?.fromDateTime)} day`
            : `${dateDifference(item?.fromDateTime)} days ago`}
        </p>
      </div>
    </div>
  );
};
export default BookingItemCard;
