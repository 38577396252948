import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./SignUp1.module.css";
import SignUpCreate from "./SignupCreate";

const SignUp1 = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const stepsHandler = async (step) => {
    setTimeout(() => {
      setVisible(true);
      setSteps(step);
    }, 300);
  };

  return (
    <Grid
      container
      spacing={0}
      className={[
        styles.parentWrapper,
        visible ? styles.visible : styles.hidden,
      ].join(" ")}
    >
      <SignUpCreate />
    </Grid>
  );
};

export default SignUp1;
