import React, { useEffect, useState } from "react";
import classes from "./UniversalSearch.module.css";
import { FaCircleArrowLeft } from "react-icons/fa6";
import SearchBox from "../SearchBox";
import MySelect from "../MySelect";
import { RxCaretSort } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import TabComponent from "../TabComponent";
import FilterButton from "../FilterButton";
import AmenitiesItemCard from "../../pages/user/Dashboard/AmenitiesDashboard/AmenitiesItemCard";
import MemberItemCard from "../MemberItemCard";
import useMember from "../../Hooks/useMember";
import useGeneralApi from "../../Hooks/useGeneralApi";
import Header from "../Header";
import PaddingContainer from "../PaddingContainer";
import Loader from "../Loader";
import { useSelector } from "react-redux";
import { loadingHandler } from "../../store/loadingSlice";
import useLoader from "../../Hooks/useLoader";
import { filteredDataHandler } from "../../helpers";

const UniversalSearch = () => {
  const navigate = useNavigate();
  const { seriesList } = useSelector((state) => state?.generalApiReducer);
  const { loading, setLoading } = useLoader();
  const [filteredData, setFilteredData] = useState({});

  const { memberList } = useMember();

  const memberHandler = (id) => {
    console.log(id);
    // navigate(`/member/${id}`);
  };

  useEffect(() => {
    if (seriesList?.length > 0) {
      setFilteredData(filteredDataHandler(seriesList));
    }
  }, [seriesList]);

  return (
    <>
      {loading || Object.keys(filteredData)?.length === 0 ? (
        <Loader />
      ) : (
        <PaddingContainer>
          <div className={classes["headerWrapper"]}>
            <FaCircleArrowLeft
              color={"var(--main-color)"}
              size={40}
              onClick={() => navigate(-1)}
            />
            <Header title={"Search"} />
          </div>
          <div className={classes["searchWrapper"]} style={{ margin: "0px" }}>
            <SearchBox label={"Search"} name={"search"} />
          </div>
          <div className={classes["tabs-section"]}>
            <FilterButton text={"Blocks"} active={true} />
            <FilterButton text={"Flats"} />
            <FilterButton text={"Properties"} />
            <FilterButton text={"ABC"} />
          </div>
          {Object.keys(filteredData).length > 0 &&
            filteredData?.map((item, index) => (
              <div className={classes["subSection"]}>
                <div className={classes["sub-heading"]}>
                  <h1>{item[0]}</h1>
                  {item[1]?.length > 1 && (
                    <p>
                      Sorting{" "}
                      <RxCaretSort size={20} style={{ strokeWidth: "1px" }} />
                    </p>
                  )}
                </div>
                <AmenitiesItemCard data={item[1]} />
              </div>
            ))}
        </PaddingContainer>
      )}
    </>
  );
};

export default UniversalSearch;
