import React, { useEffect, useRef, useState } from "react";
import { BiSolidUserRectangle } from "react-icons/bi";
import { FaBars } from "react-icons/fa";
import { FaLocationDot, FaRegFolderOpen, FaUser } from "react-icons/fa6";
import { IoHome, IoLogOut, IoNotifications } from "react-icons/io5";
import { PiUserListFill } from "react-icons/pi";
import { RxCross1 } from "react-icons/rx";
import { useSelector } from "react-redux";
import MenuItem from "../../../components/MenuItem";
import classes from "./SidebarSkeleton.module.css";
import LeftAside from "../LeftAside";
import RightAside from "../RightAside";

const SidebarSkeleton = ({ children, isSidebarClose, setIsSidebarClose }) => {
  const { user, menu } = useSelector((state) => state?.authReducer);
  const sidebarAside = useRef(null);
  const sidebarRightAside = useRef(null);
  const [active, setActive] = useState(0);
  const [isClickOnToggle, setIsClickOnToggle] = useState(false);
  const [markAsActive, setMarkAsActive] = useState(1);

  return (
    <>
      <div className={classes["sidebar__wrapper"]}>
        <LeftAside
          menuList={menu}
          sidebarAside={sidebarAside}
          isSidebarClose={isSidebarClose}
          setIsSidebarClose={setIsSidebarClose}
          active={active}
          styles={classes}
        />

        <div
          className={[
            classes["sidebar__mainContent"],
            "scrollableContainer",
            // isSidebarClose && classes["margin-left-0"],
          ].join(" ")}
          // onMouseEnter={() => {
          //   if (!isClickOnToggle) {
          //     if (sidebarAside?.current?.offsetWidth == 300)
          //       setIsSidebarClose(true);
          //     setActive(null);
          //   }
          // }}
        >
          <div className={classes["content-wrapper"]}>{children}</div>
        </div>
        <RightAside
          sidebarAside={sidebarAside}
          isSidebarClose={isSidebarClose}
          isClickOnToggle={isClickOnToggle}
          active={active}
          classes={classes}
        />
      </div>
    </>
  );
};

export default SidebarSkeleton;
