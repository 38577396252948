import React, { useState, useEffect } from "react";
import AddButton from "../../../../components/AddButton";
import FilterButton from "../../../../components/FilterButton";
import HeaderAction from "../../../../components/HeaderAction";
import AddOrEditCommunityService from "./AddOrEditCommunityService";
import classes from "./CommunityService.module.css";
import PreviewCommunityService from "./PreviewCommunityService";
import useService from "../../../../Hooks/useService";
import ServiceListCard from "../../../../components/ServiceListCard";
import useLoader from "../../../../Hooks/useLoader";
import Loader from "../../../../components/Loader";
import Header from "../../../../components/Header";
import PaddingContainer from "../../../../components/PaddingContainer";

const allListingType = [
  { label: "Recent", value: "Recent" },
  { label: "Draft", value: "Draft" },
  { label: "Deleted", value: "Deleted" },
];
const activeOrInactiveFilter = [
  { label: "Active", value: true },
  { label: "Inactive", value: false },
];
const CommunityService = () => {
  const {
    getAllCommunityServiceListing,
    selectedData,
    setSelectedData,
    addOrEditCommunityService,
    getCommunityServiceById,
    deleteCommunityServiceCategory,
    services,
  } = useService();

  //useAnnouncement apis contains all api function
  const [selectedListingStatus, setSelectedListingStatus] = useState("");
  const [isActive, setIsActive] = useState(null);
  const [viewAnnouncement, setViewAnnouncement] = useState(false);
  const { loading, setLoading } = useLoader();

  useEffect(() => {
    getAllCommunityServiceListing(selectedListingStatus, isActive);
  }, [isActive, selectedListingStatus]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {!viewAnnouncement && selectedData === undefined && (
            <>
              <Header title={"Community Services"} />
              <div className={classes["headerWrapper"]}>
                <menu className={classes["tabs-section"]}>
                  {activeOrInactiveFilter?.map((filter) => (
                    <FilterButton
                      text={filter?.label.toUpperCase()}
                      active={filter?.value === isActive}
                      onClick={() => {
                        if (isActive === filter?.value) {
                          setIsActive(null);
                        } else {
                          setIsActive(filter?.value);
                        }
                        // getAllCommunityServiceListing(
                        //   selectedListingStatus,
                        //   filter?.value
                        // );
                      }}
                    />
                  ))}
                  {allListingType?.map((listingType) => (
                    <FilterButton
                      text={listingType?.label.toUpperCase()}
                      active={listingType?.value === selectedListingStatus}
                      onClick={() => {
                        if (selectedListingStatus === listingType?.value) {
                          setSelectedListingStatus("");
                        } else {
                          setSelectedListingStatus(listingType?.value);
                        }
                        // getAllCommunityServiceListing(
                        //   listingType?.value,
                        //   isActive
                        // );
                      }}
                    />
                  ))}
                </menu>
                <AddButton
                  handler={() => {
                    setSelectedData(null);
                  }}
                />
              </div>
              <div>
                <h2 className={classes.mainTitle}>Service List</h2>
              </div>
              <div className={classes.listingWrapper}>
                {services?.map((service) => (
                  <ServiceListCard
                    data={service}
                    classeName={classes.serviceListCard}
                    onDeleteServiceCategory={deleteCommunityServiceCategory}
                    onGetCommunityServiceById={() => {
                      getCommunityServiceById(service?.serviceCategoryId);
                    }}
                  />
                ))}
                {/* <ServiceListCard /> */}
              </div>
            </>
          )}

          {!viewAnnouncement &&
            (selectedData === null || selectedData instanceof Object) && (
              <div>
                <AddOrEditCommunityService
                  selectedData={selectedData}
                  onPrevious={() => setSelectedData(undefined)}
                  onFormSubmit={addOrEditCommunityService}
                />
              </div>
            )}
          {viewAnnouncement && (
            <PreviewCommunityService
              selectedData={selectedData}
              onPrevious={() => {
                setViewAnnouncement(false);
                setSelectedData(undefined);
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default CommunityService;
