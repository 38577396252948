import React from "react";
import classes from "./RecommendationsCard.module.css";

const RecommendationsCard = ({ data }) => {
  const { status, date, announcementTitle, announcementDescription } = data;
  return (
    <div className={classes["mainWrapper"]}>
      <div className={classes["firstDiv"]}>
        <p>{status}</p>
        <p>{date}</p>
      </div>
      <div className={classes["recommendationsDetailsDiv"]}>
        <p>{announcementTitle}</p>
        <p>{announcementDescription}</p>
      </div>
    </div>
  );
};

export default RecommendationsCard;
