const checkAccess = (menu, val) => {
  const item = doesPathExist(menu, val);
  const access = {
    create: false,
    read: false,
    update: false,
    delete: false,
    others: [],
  };

  item.forEach((data) => {
    switch (data.label) {
      case "Create":
        access.create = true;
        break;
      case "Read":
        access.read = true;
        break;
      case "Update":
        access.update = true;
        break;
      case "Delete":
        access.delete = true;
        break;
      default:
        access.others = [...access.others, data.label];
    }
  });
  return access;
};

const doesPathExist = (menu, val) => {
  for (const item of menu) {
    if (
      val.toLowerCase() === "amenityDetails".toLowerCase() ||
      val.toLowerCase() === "amenityBooking".toLowerCase() ||
      val.toLowerCase() === "AmenityBookingDetails".toLowerCase()
    ) {
      return true;
    } else if (item.router.toLowerCase() === val.toLowerCase()) {
      return item.selectedApplicationAccessControlList;
    } else if (
      item.router.toLowerCase() === "" &&
      item.selectedApplicationAccessControlList.filter(
        (item) => item.label === "SubscriberDashboard"
      ).length > 0 &&
      val.toLowerCase() === "topuser"
    ) {
      return true;
    } else if (item.subModules?.length > 0 && Array.isArray(item.subModules)) {
      const accessControlList = doesPathExist(item.subModules, val);
      if (accessControlList) {
        return accessControlList;
      }
    }
  }
  return null;
};

export { checkAccess, doesPathExist };
