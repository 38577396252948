import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  filterCommunityList,
  filterCompleteCommunityList,
  filterIncompleteCommunityList,
} from "../helpers";
import {
  activeCommunity,
  login,
  setCommunitiesList,
  setIsSkip,
  setPendingCommunityList,
} from "../store/authSlice";
import { loadingHandler } from "../store/loadingSlice";
import useApi from "./useApi";

const useLogin = () => {
  const api = useApi();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const phoneRegExp = /^(\(\+\d{1,3}\))?\s?\d{10}$/;

  const form = useFormik({
    initialValues: {
      code: "+92",
      phoneNumber: "",
      password: "",
    },
    isInitialValid: false,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      code: Yup.string().required("Required"),
      phoneNumber: Yup.string()
        .required("Required")
        .matches(phoneRegExp, "Phone number is not valid"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      dispatch(loadingHandler(true));
      api
        .login(values)
        .then((response) => {
          if (response?.data?.headers?.code === 200) {
            dispatch(login(response?.data?.body));
            dispatch(
              activeCommunity(
                filterCompleteCommunityList(
                  response?.data?.body?.communityDetail
                )
              )
            );
            dispatch(setCommunitiesList(response?.data?.body?.communityDetail));
            dispatch(
              setPendingCommunityList(
                filterIncompleteCommunityList(
                  response?.data?.body?.communityDetail
                ) || null
              )
            );
            dispatch(setIsSkip(true));
            toast.success("User Logged In Successfully");
          } else {
            toast.error(response?.data?.headers?.msg);
          }
        })
        .finally(() => {
          dispatch(loadingHandler(false));
        });
    },
  });

  return {
    form,
    error,
  };
};

export default useLogin;
