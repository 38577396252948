import { Grid } from "@mui/material";
import React from "react";
import CommonCard from "../CommonCard";
import styles from "./AuthMainComponent.module.css";

const AuthMainComponent = ({ children, className }) => {
  return (
    <Grid
      lg={6}
      md={7}
      sm={12}
      xs={12}
      className={[styles.wrapper, styles.firstWrapper].join(" ")}
    >
      <CommonCard className={[styles.mainWrapper, className].join(" ")}>
        {children}
      </CommonCard>
    </Grid>
  );
};

export default AuthMainComponent;
